import axiosInstance from "./AxiosInstance";
import store from "@/store"; // Import the Vuex store

/**
 * API for interacting with payment-related endpoints.
 */
const PaymentAPI = {
  /**
   * Sets up a payment with Stripe.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the client secret and key.
   */
  setupStripePayment: async () => {
    try {
      const user = store.state.user;
      const cart = store.state.cart;

      if (!user || !cart) {
        throw new Error("User or Cart information is missing.");
      }
      const paymentDetails = {
        cartId: cart._id,
      };

      const response = await axiosInstance.post(
        "/stripe/setupStripePayment",
        paymentDetails
      );
      return response.data;
    } catch (error) {
      console.error("Error setting up Stripe payment:", error);
      throw error;
    }
  },

  updateStripePayment: async (paymentId) => {
    try {
      const user = store.state.user;
      const cart = store.state.cart;

      if (!user || !cart) {
        throw new Error("User or Cart information is missing.");
      }
      const paymentDetails = {
        cartId: cart._id,
      };

      const response = await axiosInstance.post(
        "/stripe/updateStripePayment/" + paymentId,
        paymentDetails
      );
      return response.data;
    } catch (error) {
      console.error("Error setting up Stripe payment:", error);
      throw error;
    }
  },

  /**
   * Sets up a payment with Swish.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the payment token for QR code or to open the Swish app.
   */
  setupSwishPayment: async ({ payerAlias }) => {
    try {
      const cart = store.state.cart;

      if (!cart) {
        throw new Error("Cart information is missing.");
      }

      const cartId = cart._id;
      const amount = cart.price.total.toFixed(2); // Keep as a decimal with 2 places
      const ageLimit = cart.ageLimit > 0 ? cart.ageLimit : 1;
      const paymentDetails = {
        amount: amount,
        message: "NearbyStore varor",
        currency: "SEK",
        ageLimit: ageLimit,
        payerAlias: payerAlias,
      };

      const response = await axiosInstance.post(
        `/swish/paymentrequest/${cartId}`,
        paymentDetails
      );
      return response.data;
    } catch (error) {
      console.error("Error setting up Swish payment:", error);
      throw error;
    }
  },

  /**
   * Sets up a payment with Swish.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the payment token for QR code or to open the Swish app.
   */
  getSwishPayment: async () => {
    try {
      const cart = store.state.cart;

      if (!cart) {
        throw new Error("Cart information is missing.");
      }

      const cartId = cart._id;
      const response = await axiosInstance.get(
        `/swish/paymentrequest/${cartId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error setting up Swish payment:", error);
      throw error;
    }
  },

  /**
   * Sets up a payment with Swish.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the payment token for QR code or to open the Swish app.
   */
  cancelSwishPayment: async () => {
    try {
      const cart = store.state.cart;

      if (!cart) {
        throw new Error("Cart information is missing.");
      }

      const cartId = cart._id;
      const response = await axiosInstance.post(`/swish/cancel/${cartId}`);
      return response.data;
    } catch (error) {
      console.error("Error setting up Swish payment:", error);
      throw error;
    }
  },

  signBankid: async () => {
    try {
      const cart = store.state.cart;

      if (!cart) {
        throw new Error("Cart information is missing.");
      }
      const cartId = cart._id;
      const age = cart.minAge;
      const credentials = `Jag verifierar här med att jag är över ${age} år.`;
      const resp = await fetch("https://api.ipify.org?format=json");
      const { ip } = await resp.json();
      const body = {
        signParameters: {
          endUserIp: ip,
          userVisibleData: credentials,
        },
        minAge: age,
      };
      const response = await axiosInstance.post(
        `/auth/bankid/sign/${cartId}`,
        body
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  createSetupIntent: async (userId) => {
    try {
      const response = await axiosInstance.get(`/stripe/setupIntent/${userId}`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  fetchBankid: async () => {
    try {
      const cart = store.state.cart;

      if (!cart) {
        throw new Error("Cart information is missing.");
      }
      const cartId = cart._id;

      const response = await axiosInstance.get(
        `/auth/bankid/collect/${cartId}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  cancelBankid: async () => {
    try {
      const cart = store.state.cart;

      if (!cart) {
        throw new Error("Cart information is missing.");
      }
      const cartId = cart._id;

      const response = await axiosInstance.post(
        `/auth/bankid/cancel/${cartId}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  setupStripeSubscription: async (amount, direct) => {
    try {
      const user = store.state.user;

      if (!user) {
        throw new Error("User or Cart information is missing.");
      }
      const paymentDetails = {
        amount: amount,
        userId: user._id,
        startThisMonth: direct,
      };

      const response = await axiosInstance.post(
        "/stripe/setupStripeSubscriptionPayment",
        paymentDetails
      );
      return response.data;
    } catch (error) {
      console.error("Error setting up Stripe payment:", error);
      throw error;
    }
  },
  updgradeMemberShip: async (upgrade) => {
    try {
      const user = store.state.user;

      if (!user) {
        throw new Error("User or Cart information is missing.");
      }
      const paymentDetails = {
        upgrade: upgrade,
        userId: user._id,
      };

      const response = await axiosInstance.post(
        "/stripe/upgradeStripeSubscriptionPayment",
        paymentDetails
      );
      return response.data;
    } catch (error) {
      console.error("Error setting up Stripe payment:", error);
      throw error;
    }
  },
  payOverdue: async () => {
    try {
      const user = store.state.user;

      if (!user) {
        throw new Error("User or Cart information is missing.");
      }
      const paymentDetails = {
        userId: user._id,
      };

      const response = await axiosInstance.post(
        "/stripe/overdueStripeSubscriptionPayment",
        paymentDetails
      );
      return response.data;
    } catch (error) {
      console.error("Error setting up Stripe payment:", error);
      throw error;
    }
  },
};

export default PaymentAPI;
