<template>
  <v-container fluid>
    <v-row class="ma-2" v-if="cart.productVariants.length > 0">
      <v-col sm="12" md="7" class="pa-0">
        <v-col class="pa-0">
          <p class="bold-h1">
            {{
              $t("cart") +
              " (" +
              $t("items_count", { count: totalCartItems }) +
              ")"
            }}
          </p>
          <FreeDelivery
            class="ma-2 mt-4"
            v-if="cart.pickupPoint != null || cart.pickupAddress != null"
          />

          <p class="bold-h2">{{ $t("pickup_time") }}</p>
          <v-col cols="12" class="pa-0 pt-2 pr-2">
            <v-btn
              color="primary"
              class="xl-button text-none"
              @click="openLocationSelection"
              variant="outlined"
              block
              size="x-large"
            >
              <v-icon>mdi-truck</v-icon>
              <p class="ml-2">
                {{
                  cart.pickupTime != null
                    ? getDayLabel(cart.pickupTime.pickupAt, false) +
                      " " +
                      formatTime(cart.pickupTime.pickupAt) +
                      " - " +
                      formatTime(cart.pickupTime.pickupBefore)
                    : cart.pickupPoint != undefined
                    ? $t("select_pickup_time")
                    : $t("select_pickup_location")
                }}
              </p>
            </v-btn>
          </v-col>
          <p
            v-if="cart.recipes.length > 0 || cart.shoppingLists.length > 0"
            class="bold-h2"
          >
            {{ $t("lists") + " & " + $t("recipes") }}
          </p>
          <div v-if="cart.shoppingLists.length > 0" class="">
            <v-expansion-panels variant="accordion" elevation="0">
              <v-expansion-panel
                key="recipe"
                class="pa-0"
                style="background-color: #f5f5f9; border-radius: 10px"
              >
                <v-expansion-panel-title>
                  <v-list-item style="width: 100%; height: 100%" class="pa-5">
                    <v-row class="pa-3">
                      <v-icon class="mr-1" color="primary" size="34"
                        >mdi-format-list-bulleted</v-icon
                      >
                      <p class="bold-h3 pb-2 ml-2">Listor</p>
                    </v-row></v-list-item
                  >
                </v-expansion-panel-title>
                <v-expansion-panel-text class="">
                  <v-expansion-panels
                    variant="accordion"
                    elevation="0"
                    class="pa-0"
                  >
                    <v-expansion-panel
                      v-for="(shoppingList, index) in cart.shoppingLists"
                      :key="shoppingList.shoppingList"
                      style="background-color: #f5f5f9"
                    >
                      <v-expansion-panel-title
                        :style="{
                          'border-radius': 0,
                        }"
                      >
                        <v-list-item
                          style="width: 100%; height: 100%"
                          class="pt-2 pb-2"
                        >
                          <template v-slot:prepend> </template>

                          <v-col class="ml-1 pa-0">
                            <p class="bold-h3">{{ shoppingList.name }}</p>
                            <p class="body-2 mt-2">
                              {{
                                $t("items_count", {
                                  count: shoppingList.products.length,
                                })
                              }}
                            </p>
                          </v-col></v-list-item
                        >
                      </v-expansion-panel-title>

                      <v-expansion-panel-text class="pt-2 pb-2">
                        <v-list class="pa-0">
                          <v-list-item
                            style="width: 100%; height: 100%"
                            v-for="(product, index) in shoppingList.products"
                            :key="product.productId"
                          >
                            <ShoppingListCartProduct
                              :shoppingId="shoppingList.shoppingList"
                              :product="findProductInCart(product)"
                              :recipeQuantity="product.quantity" /></v-list-item
                        ></v-list> </v-expansion-panel-text></v-expansion-panel
                  ></v-expansion-panels>
                </v-expansion-panel-text> </v-expansion-panel
            ></v-expansion-panels>
          </div>
          <div v-if="cart.recipes.length > 0" class="mt-2">
            <v-expansion-panels variant="accordion" elevation="0">
              <v-expansion-panel
                key="recipe"
                class="pa-0"
                style="background-color: #f5f5f9; border-radius: 10px"
              >
                <v-expansion-panel-title>
                  <v-list-item style="width: 100%; height: 100%" class="pa-5">
                    <v-row class="pa-3">
                      <v-icon class="mr-1" color="primary" size="34"
                        >mdi-book-open-page-variant</v-icon
                      >
                      <p class="bold-h3 pb-1 ml-2">Recept</p>
                    </v-row></v-list-item
                  >
                </v-expansion-panel-title>
                <v-expansion-panel-text class="">
                  <v-expansion-panels
                    variant="accordion"
                    elevation="0"
                    class="pa-0"
                  >
                    <v-expansion-panel
                      v-for="(recipe, index) in cart.recipes"
                      :key="recipe._id"
                      style="background-color: #f5f5f9"
                    >
                      <v-expansion-panel-title
                        :style="{
                          'border-radius': 0,
                        }"
                      >
                        <v-list-item
                          style="width: 100%; height: 100%"
                          class="pt-2 pb-2"
                        >
                          <template v-slot:prepend>
                            <v-img
                              :src="recipe.image.url"
                              :alt="recipe.image.alt"
                              height="50"
                              width="50"
                              :aspect-ratio="1 / 1"
                              cover
                              class="rounded-sm mr-4"
                            />
                          </template>

                          <v-col class="ml-1 pa-0">
                            <p class="bold-h4">{{ recipe.name }}</p>
                            <p class="body-2 mt-2">
                              {{
                                $t("items_count", {
                                  count: recipe.products.length,
                                })
                              }}
                            </p>
                          </v-col></v-list-item
                        >
                      </v-expansion-panel-title>

                      <v-expansion-panel-text class="pt-2 pb-2">
                        <v-list class="pa-0">
                          <v-list-item
                            style="width: 100%; height: 100%"
                            v-for="(product, index) in recipe.products"
                            :key="product.productId"
                          >
                            <RecipeCartProduct
                              :recipeId="recipe.recipe"
                              :product="findProductInCart(product)"
                              :recipeQuantity="product.quantity" /></v-list-item
                        ></v-list> </v-expansion-panel-text></v-expansion-panel
                  ></v-expansion-panels>
                </v-expansion-panel-text> </v-expansion-panel
            ></v-expansion-panels>
          </div>
          <p class="bold-h2">{{ $t("items") }}</p>
          <v-card
            class="body-2 pa-2 mb-4 mt-4"
            elevation="0"
            :ripple="false"
            @click="replacementPreference = !replacementPreference"
            ><v-row class="pa-4" justify="center" align="center"
              ><v-icon>mdi-swap-horizontal</v-icon>
              <v-col class="pa-0">
                <p class="ml-4 bold-h4">{{ $t("replacement_settings") }}</p>
                <p class="ml-4 body-3 pt-1">
                  {{ $t("sold_out_items_replacement") }}
                </p></v-col
              >
              <v-switch
                v-model="replacementPreference"
                hide-details
                color="primary"
                inset
              ></v-switch> </v-row
          ></v-card>
          <v-row justify="end" class="pa-4" v-if="replacementPreference">
            <v-btn
              color="primary"
              variant="outlined"
              class="button text-none"
              @click="replaceAll(true)"
            >
              {{ $t("replace_all") }}</v-btn
            >
            <v-btn
              class="ml-4 button text-none"
              variant="outlined"
              color="primary"
              @click="replaceAll(false)"
            >
              {{ $t("refund_all") }}</v-btn
            >
          </v-row>
          <v-card
            v-for="(product, index) in cart.productVariants"
            :key="product.id"
            class="pa-2 mt-2"
            elevation="0"
          >
            <v-row>
              <v-col
                align="center"
                justify="center"
                cols="3"
                style="position: relative"
              >
                <FruitIndividually
                  v-if="
                    product.productVariant.weightProduct == true &&
                    product.productVariant.categories.findIndex(
                      (e) => e._id == 'frukt-och-gront'
                    ) != -1
                  "
                  :minAge="18"
                  style="position: absolute; top: 2px; left: 2px; z-index: 1000"
                />
                <DiscountPrice
                  v-if="product.promotion"
                  style="
                    position: absolute;
                    top: 3px;
                    right: 0px;
                    z-index: 1000;
                  "
                  :scaleFactor="!$vuetify.display.xs ? 0.7 : 0.5"
                  :promotion="product.promotion"
                  :isWeight="product.weightProduct"
                />
                <v-img
                  :src="product.productVariant.thumbnail.url"
                  :alt="product.productVariant.thumbnail.alt"
                  alt="Product Image"
                  class="hover"
                  height="100"
                  @click="handleProductClick(product)"
                />
              </v-col>
              <v-col justify="center">
                <v-row class="pa-2 pt-1">
                  <v-col class="pa-0">
                    <v-col sm="12" class="pa-0">
                      <p class="bold-1">
                        {{ product.productVariant.name }}
                      </p></v-col
                    >
                  </v-col>
                </v-row>
                <v-row class="pl-2 pr-3 mt-1" align="end">
                  <v-col class="pa-0" justify="center">
                    <v-col sm="12" class="pa-0 pr-1">
                      <p class="body-3">
                        {{
                          product.productVariant.manufacturer +
                          " " +
                          product.productVariant.displayWeight
                        }}
                      </p></v-col
                    >
                    <v-col class="pa-0">
                      <span class="bold-1" v-if="product.totalDiscount > 0">
                        <span style="color: #c94631">
                          {{
                            (product.totalPrice != undefined
                              ? product.totalPrice.toFixed(2)
                              : "") + " kr"
                          }}</span
                        ><span
                          :style="{ textDecoration: 'line-through' }"
                          class="body-4"
                        >
                          {{
                            " " +
                            (product.totalPrice != undefined
                              ? (
                                  product.totalPrice + product.totalDiscount
                                ).toFixed(2)
                              : "") +
                            " kr"
                          }}</span
                        >
                      </span>
                      <p class="bold-1" v-else>
                        {{
                          (product.totalPrice != undefined
                            ? product.totalPrice.toFixed(2)
                            : "") + " kr"
                        }}
                      </p> </v-col
                    ><span v-if="product.deposit > 0" class="body-4 pr-1">{{
                      "+" + product.deposit + " kr pant"
                    }}</span></v-col
                  >
                  <v-col class="pa-0 pl-1" cols="5">
                    <ProductButton :product="product" :cartProduct="true" />
                  </v-col>
                </v-row>
                <v-row
                  class="pa-2 pt-1"
                  align="center"
                  v-if="replacementPreference"
                >
                  <v-card
                    class="pl-2 pr-2"
                    elevation="0"
                    :style="
                      (product.replacementPreference != null &&
                        product.replacementPreference.preference ==
                          'replace') ||
                      product.replacementPreference == null
                        ? 'background-color: #003A70; border: 2px solid transparent; border-radius: 6px;'
                        : 'border: 2px solid grey; border-radius: 6px;'
                    "
                    @click="
                      {
                        product.replacementPreference.preference = 'replace';
                        updateReplacement();
                      }
                    "
                  >
                    <v-col sm="12" class="pa-0">
                      <p
                        class="bold-1 pb-1"
                        :style="
                          (product.replacementPreference != null &&
                            product.replacementPreference.preference ==
                              'replace') ||
                          product.replacementPreference == null
                            ? 'color: white;'
                            : 'color: grey;'
                        "
                      >
                        {{ $t("replace") }}
                      </p></v-col
                    ></v-card
                  >

                  <v-card
                    class="pl-2 pr-2 ml-4"
                    elevation="0"
                    :style="
                      product.replacementPreference != null &&
                      product.replacementPreference.preference == 'repay'
                        ? 'background-color: #003A70; border: 2px solid transparent; border-radius: 6px;'
                        : 'border: 2px solid grey; border-radius: 6px;'
                    "
                    @click="
                      {
                        product.replacementPreference.preference = 'repay';
                        updateReplacement();
                      }
                    "
                  >
                    <v-col sm="12" class="pa-0">
                      <p
                        class="bold-1 pb-1"
                        :style="
                          product.replacementPreference != null &&
                          product.replacementPreference.preference == 'repay'
                            ? 'color: white;'
                            : 'color: grey;'
                        "
                      >
                        {{ $t("refund") }}
                      </p></v-col
                    ></v-card
                  ><v-spacer></v-spacer>
                  <v-btn
                    class="mr-2 button text-none"
                    variant="text"
                    @click="
                      cleanComment(
                        product.comment ||
                          product.replacementPreference.comment != '',
                        product
                      )
                    "
                    >{{
                      product.comment ||
                      product.replacementPreference.comment != ""
                        ? $t("clean")
                        : $t("add_comment")
                    }}</v-btn
                  >
                </v-row>
                <v-text-field
                  v-if="
                    replacementPreference &&
                    (product.comment ||
                      product.replacementPreference.comment != '')
                  "
                  v-model="product.replacementPreference.comment"
                  class="mt-4"
                  :label="$t('comment')"
                  color="primary"
                  bg-color="surface"
                  variant="outlined"
                  single-line
                  :disabled="isLoading"
                  @blur="updateReplacement"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-col>
      <v-col
        :class="
          $vuetify.display.xs || $vuetify.display.sm
            ? 'pa-0 mt-4'
            : 'pa-0 ml-4 mt-4'
        "
      >
        <v-card class="pa-4 sticky-card" elevation="0">
          <v-col>
            <v-row justify="space-between" class="bold-h3 pt-0">
              <span>{{
                $t("total") +
                " (" +
                $t("items_count", { count: totalCartItems }) +
                ")"
              }}</span>
              <span>{{ totalCartPrice }} kr</span>
            </v-row>
            <v-row
              justify="space-between"
              class="bold-h3"
              style="color: #c94631"
            >
              <span>{{ $t("total_discount") }}</span>
              <span>{{ totalDiscount }} kr</span>
            </v-row>
          </v-col>
          <!-- <v-row class="pt-5">
            <v-col>
              <a class="body-2" @click="togglePromoCodeDropdown">
                {{ $t("have_discount_code") }}
                <v-icon small class="body-2">{{
                  showPromoCodeDropdown ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </a>
            </v-col>
          </v-row>
          <div v-if="showPromoCodeDropdown" class="promo-code-container">
            <div class="promo-code-input-container">
              <v-text-field
                v-model="promoCode"
                :placeholder="$t('yes_here_is_my_code')"
                color="primary"
                variant="outlined"
                single-line
                hide-details
                class="promo-code-field"
              >
              </v-text-field>
              <v-btn color="primary" @click="applyPromoCode">{{
                $t("activate")
              }}</v-btn>
            </div>
          </div>
          Checkout Button -->
          <v-col align="center">
            <v-btn
              color="success"
              @click="handleCheckout"
              :disabled="isCartLoading"
              :loading="isCartLoading"
              size="large"
              block
              flat
              class="xl-button text-none"
            >
              {{ $t("complete_order") }}
            </v-btn>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <v-col align="center" justify="center" v-else>
      <v-icon size="40">mdi-food-variant</v-icon>
      <p class="bold-h4">{{ $t("cart_is_empty") }}</p>
      <v-btn
        class="mt-4 xl-button text-none"
        :to="{ name: 'handla' }"
        flat
        size="large"
        color="primary"
        >{{ $t("back_to_homepage") }}</v-btn
      >
    </v-col>
    <LoginModal
      :visible="loginModal"
      @close="loginModal = false"
      @done="loginDone"
    ></LoginModal>
  </v-container>
</template>

<script>
import Header from "@/components/Header.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { formatTime, getDayLabel } from "../utils.js";
import ProductButton from "../components/ProductButton.vue";
import RecipeCartProduct from "@/components/cartLogic/RecipeCartProduct.vue";
import ShoppingListCartProduct from "@/components/cartLogic/ShoppingListCartProduct.vue";
import FruitIndividually from "@/components/FruitIndividually.vue";
export default {
  name: "Checkout",
  components: {
    Header,
    RecipeCartProduct,
    ShoppingListCartProduct,
    ProductButton,
    FruitIndividually,
  },
  data() {
    return {
      loginModal: false,
      paymentMethod: "swish", // Default payment method
      promoCode: "", // Promo code input
      tryingToCheckout: false,
      replacementPreference: false,
      showPromoCodeDropdown: false, // Promo code dropdown visibility state
    };
  },
  watch: {
    locationModalIsVisible(newVal, oldVal) {
      if (
        this.tryingToCheckout == true &&
        newVal == false &&
        oldVal == true &&
        this.cart.pickupTime != null
      ) {
        this.tryingToCheckout == false;

        this.handleCheckout();
      }
    },
  },
  computed: {
    ...mapState([
      "cart",
      "localCart",
      "location",
      "isCartLoading",
      "user",
      "locationModalIsVisible",
    ]),
    ...mapGetters(["pickupPoints"]),

    /**
     * Calculates the total cart price.
     * @returns {string} The total cart price.
     */
    totalCartPrice() {
      return this.cart.price.products.toFixed(2);
    },

    /**
     * Calculates the reserved amount.
     * @returns {string} The reserved amount.
     */
    reservedAmount() {
      return (
        this.cart.price.reservedBags + this.cart.price.reservedWeight
      ).toFixed(2);
    },

    /**
     * Calculates the delivery fee.
     * @returns {string} The delivery fee.
     */
    deliveryFee() {
      return this.cart.price.deliveryFee.toFixed(2);
    },

    /**
     * Calculates the products cost.
     * @returns {string} The products cost.
     */
    productsCost() {
      return this.cart.price.products.toFixed(2);
    },

    /**
     * Calculates the total discount.
     * @returns {string} The total discount.
     */
    totalDiscount() {
      return (
        this.cart.price.productDiscount + this.cart.price.cartDiscount
      ).toFixed(2);
    },

    /**
     * Gets the total number of items in the cart.
     * @returns {number} The total number of items.
     */
    totalCartItems() {
      return this.cart.totalItems;
    },
  },
  methods: {
    formatTime,
    getDayLabel,
    ...mapActions([
      "toggleLocationModal",
      "setCategoryModal",
      "toggleCartModal",
      "addToCart",
      "removeFromCart",
      "clearCart",
      "checkoutCart",
      "updateReplacementPreferences",
    ]),
    cleanComment(clean, product) {
      product.comment = !clean;
      if (clean) {
        product.replacementPreference.comment = "";
        this.updateReplacement();
      }
    },
    replaceAll(replace) {
      this.cart.productVariants.forEach((element) => {
        element.replacementPreference.preference = replace
          ? "replace"
          : "repay";
      });

      this.updateReplacement();
    },

    updateReplacement() {
      const body = [];
      this.cart.productVariants.forEach((element) => {
        const { _id, ...replacementPreferenceWithoutId } =
          element.replacementPreference;
        replacementPreferenceWithoutId.userId = this.cart.userId;
        body.push(replacementPreferenceWithoutId);
      });
      this.updateReplacementPreferences({ body: body });
    },

    findProductInCart(product) {
      return this.cart.productVariants.find(
        (variant) => variant.productVariant._id === product.productId
      );
    },
    /**
     * Handles the checkout process.
     * Redirects to the login page if the user is not logged in.
     * Redirects to the payment page based on the selected payment method if logged in.
     */
    openLocationSelection() {
      this.toggleLocationModal();
    },
    async loginDone() {
      if (this.user != null) {
        await this.checkoutCart();
        this.$router.push({ name: "checkout" });
      }
    },
    handleProductClick(product) {
      this.$router.push({
        name: "ProductDetail",
        params: { id: product.productVariant._id },
      });
    },
    async handleCheckout() {
      try {
        this.tryingToCheckout = true;
        if (this.cart.pickupTime == null) {
          this.toggleLocationModal();
          return;
        }

        if (!this.user) {
          // Store the intended route and redirect to login
          this.loginModal = true;
          return;
        }
        this.tryingToCheckout = false;
        await this.checkoutCart();

        this.$router.push({ name: "checkout" });
      } catch (error) {}
    },

    /**
     * Selects a payment method.
     * @param {string} method - The payment method to select.
     */
    selectPaymentMethod(method) {
      this.paymentMethod = method;
    },

    /**
     * Toggles the promo code dropdown visibility.
     */
    togglePromoCodeDropdown() {
      this.showPromoCodeDropdown = !this.showPromoCodeDropdown;
    },

    /**
     * Applies the promo code.
     */
    applyPromoCode() {
      // Logic to apply promo code can be added here

      this.showPromoCodeDropdown = false;
    },
  },
};
</script>

<style scoped>
:deep(.v-expansion-panel-text__wrapper) {
  padding: 0;
}
:deep(.v-expansion-panel-title) {
  padding: 0px;
  padding-left: 0px;
  padding-right: 30px;
}
:deep(.v-expansion-panel__shadow) {
  box-shadow: none !important;
}
.sticky-card {
  position: sticky;
  top: 175px;
}
.title {
  margin-bottom: 20px;
}

.title-divider {
  margin-bottom: 30px !important;
}

.v-main {
  background-color: #f0f0f0;
  height: 100%;
}

.content-container {
  background-color: #fff;
  width: 65%;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: 100%;
}

.inner-content-container {
  margin-top: 100px;
  margin-bottom: 100px;
}

.section-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.price-summary {
  margin-bottom: 20px;
}

.total-summary {
  margin-top: 35px;
  font-size: 18px;
  font-weight: bold;
}

.discount-summary {
  margin-top: 5px;
  color: red;
}

.price-item,
.total-summary,
.discount-summary {
  display: flex;
  justify-content: space-between;
}

.order-btn {
  padding: 35px;
  text-align: center;
}

.full-height {
  height: 100%;
}

.cart-items-container {
  overflow-y: auto;
  max-height: 70%;
  padding: 20px;
}

.cart-item {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.item-left {
  display: flex;
  flex-direction: row;
}

.item-right {
  display: flex;
}

.product-image-container {
  position: relative;
  display: flex;
  align-items: center;
}

.product-image {
  width: auto;
  height: 75px;
  width: 100px;
  object-fit: contain;
}

.item-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  margin-left: 20px;
  max-width: 50%;
}

.item-name {
  font-weight: bold;
}

.item-price {
  color: #333;
  margin-top: 15px;
}

.item-amount-container {
  user-select: none;
  display: flex;
  font-size: 18px;
  align-items: center;
}

.item-amount {
  min-width: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.payment-methods {
  display: flex;
  justify-content: flex-start;
  margin: 20px;
}

.payment-method {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ccc;
  margin: 20px;
  padding: 40px;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.payment-method:first-child {
  margin-left: 0;
}

.payment-method.selected {
  border-color: blue;
}

.payment-method v-icon {
  margin-right: 10px;
}

.promo-code-link {
  text-decoration: underline;
  cursor: pointer;
  display: block;
  margin-bottom: 20px;
  color: inherit;
}

.promo-code-container {
  margin-bottom: 20px;
}

.promo-code-input-container {
  display: flex;
  align-items: center;
}

.promo-code-field {
  flex-grow: 1;
  margin-right: 10px;
}

@media (max-width: 700px) {
  .content-container {
    width: 100%;
  }
}
</style>
