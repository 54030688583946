<template>
  <!-- Main container for the cart modal, only shown if the cart is visible -->
  <v-navigation-drawer
    v-model="cartModalIsVisible"
    location="right"
    app
    :width="$vuetify.display.xs || $vuetify.display.sm ? 0 : 320"
    :permanent="!$vuetify.display.xs"
    :style="
      $vuetify.display.xs || $vuetify.display.sm
        ? 'width: 100%'
        : 'width: 320px'
    "
  >
    <!-- Container for the content of the cart modal -->

    <v-card height="100%" class="pt-1 pb-6" style="overflow-y: auto">
      <v-card-title align="center" class="pa-2">
        <p class="bold-h2">{{ $t("cart") }}</p>
      </v-card-title>
      <FreeDelivery
        color="white"
        class="ma-2 mt-0"
        v-if="cart.pickupPoint != null || cart.pickupAddress != null"
      />
      <v-container
        class="fill-height"
        fluid
        v-if="cart.productVariants.length === 0"
      >
        <v-col align="center" justify="center">
          <v-icon size="40">mdi-food-variant</v-icon>
          <p class="bold-h4">{{ $t("cart_is_empty") }}</p>
        </v-col>
      </v-container>
      <v-card-text class="pa-0" v-else>
        <div v-if="cart.shoppingLists.length > 0" class="ma-2">
          <v-expansion-panels variant="accordion" elevation="0">
            <v-expansion-panel
              key="recipe"
              class="pa-0"
              style="background-color: white; border-radius: 10px"
            >
              <v-expansion-panel-title>
                <v-list-item style="width: 100%; height: 100%" class="pa-3">
                  <v-row class="pa-3">
                    <v-icon class="mr-1" color="primary"
                      >mdi-format-list-bulleted</v-icon
                    >
                    <p class="bold-h4 pb-2 ml-2">{{ $t("lists") }}</p>
                  </v-row></v-list-item
                >
              </v-expansion-panel-title>
              <v-expansion-panel-text class="">
                <v-expansion-panels
                  variant="accordion"
                  elevation="0"
                  class="pa-0"
                >
                  <v-expansion-panel
                    v-for="(shoppingList, index) in cart.shoppingLists"
                    :key="shoppingList.shoppingList"
                    style="background-color: white"
                  >
                    <v-expansion-panel-title
                      :style="{
                        'border-radius': 0,
                      }"
                    >
                      <v-list-item
                        style="width: 100%; height: 100%"
                        class="pt-2 pb-2"
                      >
                        <template v-slot:prepend> </template>

                        <v-col class="ml-1 pa-0">
                          <p class="bold-1">{{ shoppingList.name }}</p>
                          <p class="body-4 mt-1">
                            {{
                              $t("items_count", {
                                count: shoppingList.products.length,
                              })
                            }}
                          </p>
                        </v-col></v-list-item
                      >
                    </v-expansion-panel-title>

                    <v-expansion-panel-text class="pt-2 pb-2">
                      <v-list class="pa-0">
                        <v-list-item
                          style="width: 100%; height: 100%"
                          v-for="(product, index) in shoppingList.products"
                          :key="product.productId"
                        >
                          <ShoppingListCartProduct
                            :shoppingId="shoppingList.shoppingList"
                            :product="findProductInCart(product)"
                            :recipeQuantity="product.quantity" /></v-list-item
                      ></v-list> </v-expansion-panel-text></v-expansion-panel
                ></v-expansion-panels>
              </v-expansion-panel-text> </v-expansion-panel
          ></v-expansion-panels>
        </div>
        <div v-if="cart.recipes.length > 0" class="ma-2">
          <v-expansion-panels variant="accordion" elevation="0">
            <v-expansion-panel
              key="recipe"
              class="pa-0"
              style="background-color: white; border-radius: 10px"
            >
              <v-expansion-panel-title>
                <v-list-item style="width: 100%; height: 100%" class="pa-3">
                  <v-row class="pa-3">
                    <v-icon class="mr-1" color="primary"
                      >mdi-book-open-page-variant</v-icon
                    >
                    <p class="bold-h4 pb-2 ml-2">{{ $t("recipes") }}</p>
                  </v-row></v-list-item
                >
              </v-expansion-panel-title>
              <v-expansion-panel-text class="">
                <v-expansion-panels
                  variant="accordion"
                  elevation="0"
                  class="pa-0"
                >
                  <v-expansion-panel
                    v-for="(recipe, index) in cart.recipes"
                    :key="recipe._id"
                    style="background-color: white"
                  >
                    <v-expansion-panel-title
                      :style="{
                        'border-radius': 0,
                      }"
                    >
                      <v-list-item
                        style="width: 100%; height: 100%"
                        class="pt-2 pb-2"
                      >
                        <template v-slot:prepend>
                          <v-img
                            :src="recipe.image.url"
                            :alt="recipe.image.alt"
                            height="50"
                            width="50"
                            :aspect-ratio="1 / 1"
                            cover
                            class="rounded-sm mr-4"
                          />
                        </template>

                        <v-col class="ml-1 pa-0">
                          <p class="bold-1">{{ recipe.name }}</p>
                          <p class="body-4 mt-1">
                            {{
                              $t("items_count", {
                                count: recipe.products.length,
                              })
                            }}
                          </p>
                        </v-col></v-list-item
                      >
                    </v-expansion-panel-title>

                    <v-expansion-panel-text class="pt-2 pb-2">
                      <v-list class="pa-0">
                        <v-list-item
                          style="width: 100%; height: 100%"
                          v-for="(product, index) in recipe.products"
                          :key="product.productId"
                        >
                          <RecipeCartProduct
                            :recipeId="recipe.recipe"
                            :product="findProductInCart(product)"
                            :recipeQuantity="product.quantity" /></v-list-item
                      ></v-list> </v-expansion-panel-text></v-expansion-panel
                ></v-expansion-panels>
              </v-expansion-panel-text> </v-expansion-panel
          ></v-expansion-panels>
        </div>

        <p class="bold-h4 ml-4 mt-2">{{ $t("items") }}</p>
        <div>
          <v-list class="pa-1">
            <v-list-item
              v-for="(product, index) in cart.productVariants"
              :key="product.id"
              class="pa-1"
            >
              <template v-slot:prepend> </template>

              <v-card color="white" class="pa-2">
                <v-row>
                  <v-col
                    cols="3"
                    align="center"
                    justify="center"
                    @click="handleProductClick(product)"
                    class="hover"
                    style="position: relative"
                  >
                    <DiscountPrice
                      v-if="product.promotion"
                      style="
                        position: absolute;
                        top: 3px;
                        right: 0px;
                        z-index: 1000;
                      "
                      :scaleFactor="0.5"
                      :promotion="product.promotion"
                      :isWeight="product.weightProduct"
                    />
                    <v-img
                      :src="product.productVariant.thumbnail.url"
                      alt="Product Image"
                      height="70"
                    />
                  </v-col>
                  <v-col align="space-between">
                    <v-row class="pa-2 pt-1">
                      <v-col class="pa-0">
                        <v-col sm="12" class="pa-0">
                          <p class="bold-1">
                            {{ product.productVariant.name }}
                          </p></v-col
                        >
                      </v-col>
                    </v-row>
                    <v-row class="pl-2 pr-3 mt-1 mb-1" align="end">
                      <v-col class="pa-0" justify="center">
                        <v-col sm="12" class="pa-0 pr-1">
                          <p class="body-4">
                            {{ product.productVariant.manufacturer }}
                          </p></v-col
                        >
                        <v-col class="pa-0">
                          <span class="bold-1" v-if="product.totalDiscount > 0">
                            <span style="color: #c94631">
                              {{
                                (product.totalPrice != undefined
                                  ? product.totalPrice.toFixed(2)
                                  : "") + " kr"
                              }}</span
                            ><span
                              :style="{ textDecoration: 'line-through' }"
                              class="body-4"
                            >
                              {{
                                " " +
                                (product.totalPrice != undefined
                                  ? (
                                      product.totalPrice + product.totalDiscount
                                    ).toFixed(2)
                                  : "") +
                                " kr"
                              }}</span
                            >
                          </span>
                          <p class="bold-1" v-else>
                            {{
                              (product.totalPrice != undefined
                                ? product.totalPrice.toFixed(2)
                                : "") + " kr"
                            }}
                          </p>
                        </v-col>
                        <span v-if="product.deposit > 0" class="body-4 pr-1">{{
                          "+" + product.deposit + " kr pant"
                        }}</span></v-col
                      >
                      <v-col class="pa-0" cols="5">
                        <ProductButton :product="product" :cartProduct="true" />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-list-item>

            <v-col justify="center" align="center">
              <v-dialog max-width="500">
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    v-bind="activatorProps"
                    color="error"
                    variant="flat"
                    class="xl-button text-none"
                  >
                    {{ $t("clean_cart") }}</v-btn
                  >
                </template>
                <template v-slot:default="{ isActive }">
                  <v-card :title="$t('confirm')">
                    <v-card-text>
                      {{ $t("are_you_sure_you_want_to_clear_cart") }}
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="isActive.value = false">
                        {{ $t("no") }}</v-btn
                      >

                      <v-btn @click="confirmClearCart"> {{ $t("yes") }}</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
          </v-list>
          <v-col class="pa-2">
            <v-card color="white" elevation="0">
              <v-col justify="space-between">
                <v-col>
                  <v-row justify="space-between">
                    <p class="bold-h4" style="color: #c94631">
                      {{ $t("total_discount") }}
                    </p>
                    <p class="bold-h4 error-text" style="color: #c94631">
                      {{ totalDiscount }} kr
                    </p>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row justify="space-between">
                    <p class="bold-h4">
                      {{
                        $t("total") +
                        " (" +
                        $t("items_count", { count: totalCartItems }) +
                        ")"
                      }}
                    </p>
                    <p class="bold-h4">{{ totalCartPrice }} kr</p>
                  </v-row>
                </v-col>

                <!-- Button to proceed to checkout -->
                <v-col> </v-col>
              </v-col>
            </v-card>
          </v-col>
        </div>
      </v-card-text>
      <v-card-actions> </v-card-actions>
      <!-- Custom confirmation dialog for clearing the cart -->

      <!-- Custom error dialog for checkout -->
      <div v-if="isCheckoutErrorVisible" class="confirm-clear-cart-overlay">
        <div class="confirm-clear-cart-modal">
          <h2 class="confirm-title">Fel</h2>
          <p>Något gick fel, försök igen senare</p>
          <div class="confirm-actions">
            <button
              class="confirm-btn confirm-yes"
              @click="isCheckoutErrorVisible = false"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </v-card>
    <v-col
      style="position: absolute; bottom: 2px; right: 2px; z-index: 1000"
      v-if="cart.productVariants.length !== 0"
    >
      <v-btn
        class="pa-2 xl-button text-none"
        :loading="isCheckoutLoading"
        :diabled="isCheckoutLoading"
        @click="checkout"
        variant="elevated"
        elevation="24"
        size="large"
        color="success"
        block
      >
        {{ $t("order") }}
      </v-btn>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { useRouter } from "vue-router";
import { mapFields } from "vuex-map-fields";
import ProductButton from "../ProductButton.vue";
import bag from "../../assets/images/bag.png";
import DiscountPrice from ".././DiscountPrice.vue";
import RecipeCartProduct from "./RecipeCartProduct.vue";
import ShoppingListCartProduct from "./ShoppingListCartProduct.vue";
import ListImage from "../ListImage.vue";
import FreeDelivery from "../freeDelivery.vue";

export default {
  components: {
    ListImage,
    ShoppingListCartProduct,
    RecipeCartProduct,
    ProductButton,
    DiscountPrice,
    FreeDelivery,
  },
  data() {
    return {
      bag: bag,
      // State variable to control the visibility of the clear cart confirmation dialog
      isConfirmClearCartVisible: false,
      // State variable to control the visibility of the checkout error dialog
      isCheckoutErrorVisible: false,
      // State variable to control the loading state of the checkout process
      isCheckoutLoading: false,
      drawer: true,
    };
  },
  computed: {
    ...mapFields(["cartModalIsVisible"]),
    ...mapState(["cart", "localCart"]),
    /**
     * Calculates the total price of the items in the cart.
     * @return {string} The total price formatted to 2 decimal places.
     */
    totalCartPrice() {
      return this.cart.price.products?.toFixed(2) || "0.00";
    },
    /**
     * Calculates the total discount from productDiscount and cartDiscount.
     * @return {string} The total discount formatted to 2 decimal places.
     */
    totalDiscount() {
      const productDiscount = this.cart.price.productDiscount || 0;
      const cartDiscount = this.cart.price.cartDiscount || 0;
      return (productDiscount + cartDiscount).toFixed(2);
    },
    /**
     * Gets the total number of items in the cart.
     * @return {number} The total number of items in the cart.
     */
    totalCartItems() {
      return this.cart.totalItems;
    },
  },
  methods: {
    ...mapActions([
      "toggleCartModal",
      "addToCart",
      "removeFromCart",
      "clearCart",
      "checkoutCart",
    ]),
    findProductInCart(product) {
      return this.cart.productVariants.find(
        (variant) => variant.productVariant._id === product.productId
      );
    },
    goToRecipe(id) {
      this.$router.push({
        name: "recipeDetail",
        params: { id: id },
      });
    },
    handleProductClick(product) {
      this.$router.push({
        name: "ProductDetail",
        params: { id: product.productVariant._id },
      });
    },
    /**
     * Closes the cart modal when clicking outside of it.
     */
    closeOnOutsideClick() {
      this.toggleCartModal();
    },
    /**
     * Handles the checkout process.
     * Displays a loading indicator while processing and shows an error message if it fails.
     */

    async checkout() {
      this.isCheckoutLoading = true;
      try {
        this.toggleCartModal(); // Close the cart modal
        this.$router.push({ name: "cart" });
      } catch (error) {
        this.isCheckoutErrorVisible = true;
      } finally {
        this.isCheckoutLoading = false;
      }
    },

    /**
     * Shows the confirmation dialog for clearing the cart.
     */
    showConfirmClearCart() {
      this.isConfirmClearCartVisible = true;
    },
    /**
     * Clears the cart and hides the confirmation dialog.
     * Redirects to the homepage if the current route is payment-related.
     */
    async confirmClearCart() {
      await this.clearCart();
      this.isConfirmClearCartVisible = false;
    },
  },
  setup() {
    const router = useRouter();
    return { router };
  },
};
</script>

<style scoped>
:deep(.v-expansion-panel-text__wrapper) {
  padding: 0;
}
:deep(.v-expansion-panel-title) {
  padding: 0px;
  padding-left: 0px;
  padding-right: 30px;
}
:deep(.v-expansion-panel__shadow) {
  box-shadow: none !important;
}

/* Hide the scrollbar */
::-webkit-scrollbar {
  width: 0px;
  height: 0px; /* For horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

/* For other browsers */
html {
  scrollbar-width: none; /* Firefox */
}

html::-ms-scrollbar {
  display: none; /* IE and Edge */
}

.fill-reamaining {
  height: calc(100% - 170px); /* Firefox */
}
</style>
