<template>
  <v-col
    style="background: linear-gradient(#8ab7e9 75%, #ececec)"
    class="pl-8 pr-8 pt-4 pb-15"
  >
    <v-row>
      <v-col justify="center" align="center " style="color: #f5f5f9">
        <v-img height="200" :src="star" contain alt="star-icon"></v-img>
        <p class="bold-h1">
          {{ $t("free_membership") }}
        </p>
        <p class="body-2 mt-2">
          {{ $t("no_fees_cancel_anytime") }}
        </p></v-col
      >
      <v-col justify="center" align="center" style="color: #f5f5f9">
        <v-img height="200" :src="piggySaving" contain alt="star-icon"></v-img>
        <p class="bold-h1">{{ $t("top_up_your_account") }}</p>
        <p class="body-2 mt-2">
          {{ $t("top_up_ns_account") }}
        </p></v-col
      >
    </v-row>
    <v-col justify="center" align="center" class="mt-8" style="color: #f5f5f9">
      <v-img height="200" :src="budget" contain alt="star-icon"></v-img>
      <p class="bold-h1">{{ $t("track_budget_lower_prices") }}</p>
      <p class="body-2 mt-2">
        {{ $t("ns_account_benefits") }}
      </p></v-col
    >
    <v-row justify="center" align="center" class="mt-4 mb-10">
      <v-btn
        elevation="0"
        color="primary"
        class="pb-14 pt-4 pr-10 pl-10 text-none"
        @click="becomeMember()"
        ><p class="bold-h3">{{ $t("become_member_now") }}</p></v-btn
      ></v-row
    >
  </v-col>
  <v-col align="center">
    <v-row justify="center" class="mt-0">
      <v-col align="center" cols="12" sm="7">
        <p class="bold-h1">{{ $t("Förmåner") }}</p>
      </v-col>
    </v-row>
    <v-row justify="space-evenly" class="mt-4">
      <v-col align="start" cols="12" sm="3">
        <p class="bold-h3">{{ $t("member_offers") }}</p>
        <p class="body-2 mt-2">
          {{ $t("exclusive_discounts") }}
        </p>
      </v-col>
      <v-col align="start" cols="12" sm="3">
        <p class="bold-h3">{{ $t("deposit_bonus") }}</p>
        <p class="body-2 mt-2">
          {{ $t("earn_more_money") }}
        </p>
        <v-row align="start" justify="start" class="pa-2 mt-0">
          <v-icon size="25" color="warning">mdi-star</v-icon>
          <p class="body-2 mt-0 ml-2">
            {{ $t("start_bonus") }}
          </p></v-row
        >
        <v-row align="center" justify="start" class="pa-2 mt-0">
          <v-icon size="25" color="warning">mdi-star</v-icon>
          <p class="body-2 mt-0 ml-2">
            {{ $t("bonus_tiers") }}
          </p></v-row
        >
        <p class="body-2 mt-4">
          {{ $t("loyalty_bonus") }}
        </p>
      </v-col>
      <v-col align="start" cols="12" sm="3">
        <p class="bold-h3">{{ $t("your_price") }}</p>
        <p class="body-2 mt-2">
          {{ $t("personalized_prices") }}
        </p>
      </v-col>
    </v-row>
  </v-col>
  <v-col align="center">
    <v-row justify="center" class="mt-10">
      <v-col align="center" cols="12" sm="7">
        <p class="bold-h1">{{ $t("how_to_become_a_member") }}</p>
      </v-col>
    </v-row>
    <v-row justify="space-evenly" class="mt-8">
      <v-col align="center" cols="12" sm="3">
        <v-avatar color="surface" size="62"
          ><p class="bold-h3 pb-1" style="color: #003a70">1</p></v-avatar
        >
        <p class="bold-h3">{{ $t("step_1") }}</p>
        <p class="body-2 mt-2">
          {{ $t("membership_steps_1") }}
        </p>
      </v-col>
      <v-col align="center" cols="12" sm="3">
        <v-avatar color="surface" size="62"
          ><p class="bold-h3 pb-1" style="color: #003a70">2</p></v-avatar
        >
        <p class="bold-h3">{{ $t("step_2") }}</p>
        <p class="body-2 mt-2">
          {{ $t("membership_steps_2") }}
        </p>
      </v-col>
      <v-col align="center" cols="12" sm="3">
        <v-avatar color="surface" size="62"
          ><p class="bold-h3 pb-1" style="color: #003a70">3</p></v-avatar
        >
        <p class="bold-h3">{{ $t("step_3") }}</p>
        <p class="body-2 mt-2">
          {{ $t("membership_steps_3") }}
        </p>
      </v-col>
    </v-row>
  </v-col>
  <v-row justify="center" align="center" class="mt-4 mt-10">
    <v-btn
      elevation="0"
      color="primary"
      class="pb-14 pt-4 pr-10 pl-10 text-none"
      @click="becomeMember()"
      ><p class="bold-h3">{{ $t("become_member_now") }}</p></v-btn
    ></v-row
  >

  <v-col
    style="background-color: rgba(138, 183, 233, 0.7)"
    class="pl-8 pr-8 mt-14"
  >
    <p class="bold-h2">{{ $t("faq") }}</p>
    <p class="bold-h3 mt-6">{{ $t("how_to_end_membership") }}</p>
    <p class="body-2">{{ $t("membership_cancellation") }}</p>
    <p class="bold-h3 mt-6">{{ $t("credits_after_cancellation_title") }}</p>
    <p class="body-2">{{ $t("credits_after_cancellation") }}</p>
    <p class="bold-h3 mt-6">{{ $t("credits_withdrawal_title") }}</p>
    <p class="body-2">{{ $t("credits_withdrawal") }}</p>

    <p class="bold-h3 mt-6">
      {{ $t("what_are_the_terms_for_the_membership") }}
    </p>
    <v-col>
      <ul class="body-2">
        <li>
          {{ $t("terms_description") }}
        </li>
        <li class="mt-4">
          {{ $t("auto_deposit") }}
        </li>
        <li class="mt-4">
          {{ $t("please_see_our") }}
          <router-link
            :to="{ name: 'userterms' }"
            class="pr-1"
            style="color: black"
            >{{ $t("terms_of_service") }}</router-link
          >
          {{ $t("full_terms_and_conditions_info") }}
        </li>
      </ul>
    </v-col>
  </v-col>
  <LoginModal
    :visible="loginModal"
    @close="loginModal = false"
    @done="loginDone"
  ></LoginModal>
</template>
<script>
import bag2 from "../assets/images/bag.png";
import food_half from "../assets/images/food_half.png";
import star from "../assets/images/star.png";
import budget from "../assets/images/budget.png";
import piggySaving from "../assets/images/piggy-saving.png";
import UserAPI from "@/api/UserAPI"; // Import the UserAPI
import { mapState, mapActions, mapGetters } from "vuex";
import LoginModal from "@/components/LoginModal.vue";
export default {
  data() {
    return {
      bag2: bag2,
      food_half: food_half,
      piggySaving: piggySaving,
      budget: budget,
      star: star,
      loginModal: false,
      email: "",
      message: "",
      form: false,
      emailRules: [
        (value) => {
          if (value) return true;

          return "Ange en e-postadress";
        },
        (value) => {
          if (/.+@.+\..+/.test(value)) return true;

          return "E-postadressen är inte giltig.";
        },
      ],
    };
  },
  computed: {
    ...mapState([
      "cart",
      "localCart",
      "location",
      "isCartLoading",
      "user",
      "locationModalIsVisible",
    ]),
  },
  methods: {
    async onSubmit() {
      this.message = "";
      const { valid } = await this.$refs.form.validate();

      if (!valid) return;
      await UserAPI.joinWaitlist(this.email);
      this.email = "";
      this.$store.dispatch("vtoast/showToast", {
        message: "Vi har mottagit ditt svar.",
        color: "success",
      });
    },
    loginDone() {
      console.log(this.user);
      this.loginModal = false;
      if (this.user != null) {
        this.$router.push({ name: "becomeMember" });
      }
    },
    async becomeMember() {
      console.log(this.user);
      if (!this.user) {
        // Store the intended route and redirect to login
        this.loginModal = true;
        return;
      }
      this.$router.push({ name: "becomeMember" });
    },
  },

  async mounted() {},
};
</script>
