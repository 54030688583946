<template>
  <v-container class="mt-0 pt-0">
    <v-row class="pa-4" align="center" justify="center">
      <v-col align="center">
        <p class="bold-h1">
          {{
            step == 2
              ? $t("finish_nearbystore_membership")
              : $t("nearbystore_membership")
          }}
        </p>
        <v-window disabled v-model="step" class="mt-5">
          <v-window-item disabled :value="1">
            <v-col cols="12" lg="8" class="pa-0" align="start">
              <v-card elevation="0"
                ><v-col class="pl-4 pt-2 pr-8">
                  <p class="bold-h3 text-center">{{ $t("benefits") }}</p>
                  <v-row class="pa-4" align="center">
                    <v-icon color="success" size="x-large"
                      >mdi-check-circle</v-icon
                    >
                    <v-col class="pa-0 ml-4"
                      ><p class="bold-h4">{{ $t("member_offers") }}</p>
                      <p class="body-3">
                        {{ $t("exclusive_discounts") }}
                      </p></v-col
                    ></v-row
                  >

                  <p class="bold-h3 mt-8 text-center">
                    {{
                      value >= 1500
                        ? $t("congratulations_all_bonuses")
                        : "Sätt in " +
                          (1500 - value).toFixed(0) +
                          " kr mer för att få"
                    }}
                  </p>
                  <v-row class="pa-4" align="center">
                    <v-icon
                      :color="value >= 1500 ? 'success' : 'error'"
                      size="x-large"
                      >{{
                        value >= 1500 ? "mdi-check-circle" : "mdi-close-circle"
                      }}</v-icon
                    >
                    <v-col class="pa-0 ml-4"
                      ><p class="bold-h4">{{ $t("deposit_bonus") }}</p>
                      <p class="body-3">{{ $t("earn_more_money") }}</p></v-col
                    ></v-row
                  >
                  <v-row class="pa-4" align="center">
                    <v-icon
                      :color="value >= 1500 ? 'success' : 'error'"
                      size="x-large"
                      >{{
                        value >= 1500 ? "mdi-check-circle" : "mdi-close-circle"
                      }}</v-icon
                    >
                    <v-col class="pa-0 ml-4"
                      ><p class="bold-h4">{{ $t("your_price") }}</p>
                      <p class="body-3">
                        {{ $t("personalized_prices") }}
                      </p></v-col
                    ></v-row
                  >
                  <v-col class="pa-4" align="center">
                    <p class="mt-4 bold-h4">
                      {{ $t("monthly_deposit", { monthly_amount: value }) }}
                    </p>

                    <v-row align="center" class="mt-2">
                      <v-col>
                        <v-slider
                          v-model="value"
                          :max="5000"
                          :min="500"
                          :step="500"
                          inset
                          :disabled="nextStepLoading"
                          color="primary"
                          thumb-label
                          :class="
                            !$vuetify.display.xs && !$vuetify.display.sm
                              ? 'custom-slider'
                              : 'custom-sm-slider'
                          "
                          thumb-color="secondary"
                        ></v-slider>
                        <v-row
                          :class="
                            !$vuetify.display.xs && !$vuetify.display.sm
                              ? 'custom-row'
                              : 'custom-sm-row'
                          "
                        >
                          <p class="bold-h4 mr-4">500</p>
                          <p
                            :class="
                              !$vuetify.display.xs && !$vuetify.display.sm
                                ? 'ml-6 bold-h4'
                                : 'ml-5 bold-h4'
                            "
                          >
                            1500
                          </p>
                          <v-spacer></v-spacer>
                          <p class="bold-h4">5000+</p>
                        </v-row>
                      </v-col>
                      <v-col sm="4">
                        <v-text-field
                          v-model="value"
                          class="mt-5"
                          clearable
                          :rules="textFieldRules"
                          :disabled="nextStepLoading"
                          validate-on="submit"
                          color="primary"
                          bg-color="surface"
                          variant="outlined"
                          type="number"
                          label="Ange summa"
                          persistent-hint
                      /></v-col> </v-row
                  ></v-col>
                  <p class="bold-h4 text-center pb-10 mt-4">
                    {{
                      $t("next_deposit_date", {
                        amount: value,
                        date: getNextDepositDate(),
                      })
                    }}
                  </p>
                </v-col></v-card
              ></v-col
            >
          </v-window-item>
          <v-window-item disabled :value="2">
            <v-col cols="12" lg="8">
              <v-card class="sticky-card" elevation="0">
                <v-col align="center" class="pa-4 pl-8 pr-8 pt-0">
                  <v-row justify="start" class="mt-2">
                    <v-btn
                      @click="step = 1"
                      variant="text"
                      class="xl-button text-none"
                      color="primary"
                      >{{ $t("back") }}</v-btn
                    ></v-row
                  >

                  <p class="bold-h3">
                    {{ $t("monthly_deposit", { monthly_amount: value }) }}
                  </p>
                  <p class="bold-h3">
                    {{ $t("next_deposit") + " " + getNextDepositDate() }}
                  </p>

                  <div id="payment-element" class="mt-5"></div>
                  <p class="mt-10 bold-h3">
                    {{ $t("pay_now_amount", { value: value }) }}
                  </p>
                  <v-btn
                    elevation="0"
                    color="primary"
                    @click="handleSubmit"
                    :loading="isProcessing"
                    :disabled="isProcessing"
                    class="pb-14 mt-4 pt-4 pr-10 pl-10 text-none"
                    ><p class="bold-h3">{{ $t("become_member_now") }}</p></v-btn
                  >
                </v-col>
              </v-card>
            </v-col></v-window-item
          ></v-window
        >
        <v-col cols="12" lg="8" class="pa-0 mt-5" v-if="step == 1">
          <v-btn
            block
            @click="nextStep"
            class="pa-8 text-none"
            color="primary"
            elevation="0"
            :loading="nextStepLoading"
            :disabled="nextStepLoading"
            ><p class="bold-h3 pb-2">{{ $t("next") }}</p></v-btn
          >
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import bag2 from "../assets/images/bag.png";
import food_half from "../assets/images/food_half.png";
import star from "../assets/images/star.png";
import budget from "../assets/images/budget.png";
import piggySaving from "../assets/images/piggy-saving.png";
import UserAPI from "@/api/UserAPI"; // Import the UserAPI
import { mapState, mapActions, mapGetters } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import LoginModal from "@/components/LoginModal.vue";
import PaymentAPI from "@/api/PaymentAPI";
import axios from "axios";
import {
  getDayMonth,
  get26DayMonthYear,
  getNextMonth,
  getNextDepositDate,
} from "../utils.js";
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  data() {
    return {
      step: 1,
      value: 1500,
      bag2: bag2,
      direct: false,
      food_half: food_half,
      piggySaving: piggySaving,
      budget: budget,
      star: star,
      nextStepLoading: false,
      isProcessing: false,
      loginModal: false,
      email: "",
      paymentIntent: undefined,
      stripe: {},
      items: ["Medlemskap", "Checkout"],
      elements: {},
      publishableKey: process.env.VITE_APP_STRIPE_KEY,
      message: "",
      form: false,
      emailRules: [
        (value) => {
          if (value) return true;

          return "Ange en e-postadress";
        },
        (value) => {
          if (/.+@.+\..+/.test(value)) return true;

          return "E-postadressen är inte giltig.";
        },
      ],
    };
  },
  computed: {
    ...mapState([
      "cart",
      "localCart",
      "location",
      "isCartLoading",
      "user",
      "locationModalIsVisible",
      "currentLang",
    ]),
  },
  methods: {
    getNextDepositDate,
    getNextMonth,
    ...mapActions(["getUser"]),
    async onSubmit() {
      this.message = "";
      const { valid } = await this.$refs.form.validate();

      if (!valid) return;
      await UserAPI.joinWaitlist(this.email);
      this.email = "";
      this.$store.dispatch("vtoast/showToast", {
        message: "Vi har mottagit ditt svar.",
        color: "success",
      });
    },
    async nextStep() {
      this.nextStepLoading = true;
      try {
        // Fetch the client secret from the server
        if (this.value < 500) {
          this.value = 1500;
          this.$store.dispatch("vtoast/showToast", {
            message: this.t("value_above_500"),
            color: "error",
            timer: 5000,
          });
          this.nextStepLoading = false;
          return;
        }
        this.paymentIntent = await PaymentAPI.setupStripeSubscription(
          this.value.toString(),
          this.direct
        );

        const loader = "auto";
        this.elements = this.stripe.elements({
          customerOptions: {
            customer: this.user.stripeId,
            ephemeralKey: this.paymentIntent.ephemeralKey,
          },
          loader,
          allow_redisplay: "always",
          clientSecret: this.paymentIntent.client_secret,
          locale: this.currentLang,
        });
        this.nextStepLoading = false;
        window.scrollTo(0, 0);
        this.step = 2;
        await setTimeout(50);
        // Create and mount the payment element
        this.paymentElement = this.elements.create("payment");
        this.paymentElement.mount("#payment-element");
      } catch (error) {
        this.nextStepLoading = false;
        // Handle errors during Stripe initialization
        console.error("Error initializing Stripe:", error);
      }
    },
    loginDone() {
      console.log(this.user);
      console.log("LOGINDONE");
      this.loginModal = false;
      if (this.user != null) {
        this.$router.push({ name: "becomeMember" });
      }
    },
    async becomeMember() {
      console.log(this.user);
      console.log("LOGINDONE");
      if (!this.user) {
        // Store the intended route and redirect to login
        this.loginModal = true;
        return;
      }
      this.$router.push({ name: "becomeMember" });
    },
    async handleSubmit() {
      try {
        this.isProcessing = true;
        console.log("CONFIRMING PAYMENT");
        // Confirm the payment with Stripe
        const { error, paymentIntent } = await this.stripe.confirmPayment({
          elements: this.elements,
          confirmParams: {
            return_url:
              process.env.VITE_APP_FIREBASE_DEVELOPMENT == "production"
                ? "https://nearbystore.se/handla/member"
                : "https://dev.nearbystore.se/handla/member",
          },
          redirect: "if_required",
        });
        console.log(paymentIntent);
        if (error) {
          console.error("Error confirming payment:", error);
          this.isProcessing = false;
        } else if (
          paymentIntent &&
          (paymentIntent.status === "succeeded" ||
            paymentIntent.status === "requires_capture")
        ) {
          this.$store.dispatch("vtoast/showToast", {
            message:
              "Grattis du är nu medlem på NearbyStore. vi har satt in " +
              (this.value + this.value * 0.01) +
              " krediter på ditt konto. Du kommer nu att omdirigeras till en ny sida",
            color: "success",
            timer: 7000,
          });
          setTimeout(async () => {
            console.log("PUSHIN MEMBER PAGE");
            this.isProcessing = false;
            await this.getUser();
            await setTimeout(500);
            console.log("PUSHIN MEMBER PAGE");
            this.$router.push({ name: "member" });
          }, 7000);
        } else {
          this.isProcessing = false;
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          // Extract the reason from the error response
          const reason = error.response?.data?.reason || "An error occurred";
          if (reason == "promotion-error") {
            this.promoCode = "";
          }
          this.$store.dispatch("vtoast/showToast", {
            message: this.t(reason),
            color: "error",
            timer: 5000,
          });
        }
        this.isProcessing = false;
      }
    },
  },

  async mounted() {
    const today = new Date();
    const current26th = new Date(today.getFullYear(), today.getMonth(), 26);
    const diffInDays = (current26th - today) / (1000 * 60 * 60 * 24);
    console.log("diffInDays", diffInDays);
    this.direct = !(diffInDays < 79 && diffInDays >= 0);
    this.stripe = await loadStripe(this.publishableKey, {
      betas: ["elements_customers_beta_1"],
      apiVersion: "2019-11-05",
    });
  },
};
</script>
<style scoped>
.sticky-card {
  position: sticky;
  top: 175px;
}
.custom-slider {
  width: 300px;
}
.custom-sm-slider {
  width: 230px;
}
.custom-row {
  width: 340px;
}
.custom-sm-row {
  width: 270px;
}
:deep(.v-slider-track__background) {
  height: 15px !important;
}
:deep(.v-slider-track__fill) {
  height: 15px !important;
}
:deep(.v-slider-thumb__surface) {
  height: 30px !important;
  width: 30px !important;
}
</style>
