import store from "./store";
import { addMonths, format } from "date-fns";
import { sv, enUS } from "date-fns/locale";

export function capitalizeFirstLetter(string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function formatTime(inDate) {
  const date = new Date(inDate);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}

export function getNextDepositDate() {
  const now = new Date();
  const next_deposit = new Date();
  next_deposit.setDate(26);

  if (now.getDate() > 12) {
    next_deposit.setMonth(next_deposit.getMonth() + 1);
  }

  const locale = store.state.currentLang === "sv" ? sv : enUS;
  return format(next_deposit, "do MMMM", { locale });
}
export function getDayMonth(inDate) {
  console.log(store.state.currentLang);
  const locale = store.state.currentLang == "sv" ? sv : enUS;
  return format(new Date(inDate), "do MMMM", {
    locale: locale,
  });
}

export function get26DayMonthYear(inDate) {
  const date = new Date(inDate);
  date.setDate(26);

  const locale = store.state.currentLang == "sv" ? sv : enUS;
  return format(date, "do MMMM", {
    locale: locale,
  });
}
export function getNextMonth(addMonths = 0) {
  const date = new Date();
  console.log("MONTH", date.getMonth());
  date.setMonth(date.getMonth() + addMonths);
  date.setDate(26);
  if (new Date() > date) {
    if (date.getMonth() == 11) {
      date.setFullYear(date.getFullYear() + 1);
    } else {
      date.setMonth(date.getMonth() + 1);
    }
  }
  const locale = store.state.currentLang == "sv" ? sv : enUS;
  return format(date, "do MMMM", {
    locale: locale,
  });
}

export function getMonthYear(inDate) {
  const locale = store.state.currentLang == "sv" ? sv : enUS;
  return format(inDate, "MMMM yyyy", {
    locale: locale,
  });
}
export function getDayLabel(inDate, withDate) {
  const date = new Date(inDate);
  const today = new Date();

  // Remove time component from both dates to compare only the date part

  const todayDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const inputDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );

  const diffTime = inputDate - todayDate;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays === 0) {
    return this.$t("today") + ", " + (withDate ? this.$d(date, "short") : "");
  } else if (diffDays === 1) {
    return (
      this.$t("tomorrow") + ", " + (withDate ? this.$d(date, "short") : "")
    );
  } else {
    return capitalizeFirstLetter(this.$d(date, "long"));
  }
}
