<template>
  <!-- Modal Overlay -->
  <v-dialog
    v-model="show"
    width="900px"
    height="80vh"
    scrollable
    close-on-back
    @click:outside="decline"
  >
    <v-card v-if="loading">
      <v-row align="center" justify="center">
        <v-progress-circular
          color="primary"
          :size="100"
          indeterminate
        ></v-progress-circular>
      </v-row>
    </v-card>
    <!-- Modal Content -->
    <v-card v-else color="background" class="pa-4">
      <!-- Close Button -->
      <v-row align="end" justify="end">
        <v-btn
          elevation="0"
          @click="decline"
          icon="mdi-close"
          size="x-large"
          color="background"
            aria-label="Stäng"
        ></v-btn>
      </v-row>

      <!-- Product Information Section -->
      <div class="product-information">
        <AgeRestriction
        v-if="product.minAge > 0"
        :minAge="product.minAge"
        style="position: absolute; top: 20px; left: 20px; z-index: 1000"
      />
      <NudgeProduct  
      v-if="cart != null && cart.pickupPoint != null && cart.pickupPoint.products.includes(product._id)"
      :small="false"
      style="position: absolute; top:20px; left: 20px; z-index: 1001"></NudgeProduct>
      <FruitIndividually
        v-if="product.weightProduct == true && product.categories
          .findIndex((e) => e._id == 'frukt-och-gront') != -1"
         :size="{ width: 120, height: 45 }"
         :textClass="'pt-2 bold-h4'"
        style="position: absolute; top: 2px; left: 2px; z-index: 1000"
      />
        <!-- Product Image -->
        <div class="modal-product-image">
          <div style="position: relative; display: inline-block;">
          <img :src="product.mainImage.url" alt="Product Image"  style="display: block;">                <DiscountPrice
        v-if="product.promotion"
       style="position: absolute; top: -40px; right: -40px; z-index: 1000"
                :scaleFactor="1.8"
        :promotion="product.promotion"
        :isWeight="product.weightProduct"
      />   </img></div>
          <!-- Breadcrumbs -->
          <Breadcrumbs class="mt-2" :items="breadcrumbItems" />
        </div>

        <!-- Product Details -->
        <div class="modal-product-details">
          <v-col class="pa-0" sm="12">
            <p class="bold-h3">{{ product.name }}</p>
            <p class="body-3">
            {{ product.manufacturer + " " + product.displayWeight }}
          </p>
          <div
            class="bold-1"
            v-if="product.weightProduct && product.weightInKg != null"
          >
            <p
              v-if="product.promotion && product.promotion.minQuantity == 1"
              style="color: #c94631"
            >
              {{
                "ca. " +
                (product.promotion.discountValue * product.weightInKg).toFixed(
                  2
                ) +
                " kr/st"
              }}
            </p>
            <p v-else>
              {{
                "ca. " +
                (product.price * product.weightInKg).toFixed(2) +
                " kr/st"
              }}
            </p>
          </div>
          <p v-else class="body-3">
            {{
              $t("comparison_price") +
              " " +
              product.comparePrice +
              " " +
              product.compareUnit
            }}
          </p>        <p v-if="product.promotion && product.promotion.quantityLimit > 0" class="body-3"> {{"Max " +product.promotion.quantityLimit + " st/kund"}}</p></v-col
          >
          <v-col class="pa-0 mb-2" cols="12" justify="end" align-self="end">
          <div v-if="product.promotion">
            <div v-if="product.promotion.minQuantity == 1">
              <p class="bold-1" style="color: #c94631">
                <span class="bold-h2">
                  {{ product.promotion.discountValue.toFixed(2) }}</span
                >
                {{
                  "/" +
                  (product.weightProduct ? "kg" : $t("piece_abbreviation"))
                }}
              </p>
              <p class="body-3" :style="{ textDecoration: 'line-through' }">
                <span> {{ product.price }}</span>
                {{
                  "/" +
                  (product.weightProduct ? "kg" : $t("piece_abbreviation"))
                }}
              </p>
            </div>
            <p class="bold-h4" v-else>
              <span class="font-weight-bold text-h6"> {{ product.price }}</span>
              {{
                "/" + (product.weightProduct ? "kg" : $t("piece_abbreviation"))
              }}
            </p>
          </div>
          <p class="bold-h4" v-else>
            <span class="font-weight-bold text-h6"> {{ product.price }}</span>
            {{
              "/" + (product.weightProduct ? "kg" : $t("piece_abbreviation"))
            }}
          </p>
          
        </v-col>
          <v-row class="pa-2">
            <v-col algin="center" class="pa-0" sm="5">
              <ProductButton :product="product"></ProductButton>
            </v-col>
            <v-row align="center" justify="end">
              <SaveProduct :product="product"
            /></v-row>
          </v-row>
          <!-- Tabs Section -->
          <v-tabs
            v-model="tab"
            background-color="background"
            class="product-tabs"
            v-if="
              product.ingredients ||
              (product.nutrition && product.nutrition.length > 0)
            "
          >
            <v-tab
              v-if="product.description"
              value="description"
              class="button text-none"
              >{{ $t("information") }}</v-tab
            >
            <v-tab
              value="ingredients"
              v-if="product.ingredients"
              class="button text-none"
              >{{ $t("contents") }}</v-tab
            >
            <v-tab
              value="nutrition"
              class="button text-none"
              v-if="product.nutrition && product.nutrition.length > 0"
              >{{ $t("nutritional_values") }}</v-tab
            >
          </v-tabs>

          <v-window v-model="tab">
            <v-window-item value="description">
              <v-card class="body-1 mt-2" color="background" flat>
                <v-card-text>{{ product.description }}</v-card-text>
              </v-card>
            </v-window-item>
            <v-window-item value="ingredients">
              <v-card class="body-1" color="background" flat>
                <v-card-text>{{ product.ingredients }}</v-card-text>
              </v-card>
            </v-window-item>
            <v-window-item value="nutrition">
              <v-card class="body-1" color="background" flat>
                <v-card-text>
                  <div class="nutrition-table">
                    <thead>
                      <tr>
                        <th class="text-left">Typ</th>
                        <th class="text-left">Värde</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="nutrient in product.nutrition"
                        :key="nutrient.type"
                      >
                        <td>{{ nutrient.type }}</td>
                        <td>{{ nutrient.value }} {{ nutrient.unit }}</td>
                      </tr>
                    </tbody>
                  </div>
                </v-card-text>
              </v-card>
            </v-window-item>
          </v-window>
        </div>
      </div>

      <div class="bold-h3">{{ $t("also_bought") }}</div>

      <v-row justify="center" align="center" class="pa-4 mt-2">
        <v-col sm="12" lg="11" xl="10" class="pa-0">
          <v-row justify="center" align="center" class="pa-0">
            <v-col
              v-for="(product, index) in relatedProducts"
              :key="index"
              class="custom5cols pa-0"
            >
              <ProductCard :product="product" :replace="true"></ProductCard>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CategoryProductAPI from "@/api/CategoryProductAPI";
import SaveProduct from "@/components/SaveProduct.vue"; // Import SaveProduct component
import ProductCard from "./ProductCard.vue";
import ProductButton from "./ProductButton.vue";
import Breadcrumbs from "./Breadcrumbs.vue";
import { useI18n } from "vue-i18n";
import DiscountPrice from "./DiscountPrice.vue";
import AgeRestriction from "./AgeRestriction.vue";
import FruitIndividually from "./FruitIndividually.vue";
import NudgeProduct from "./NudgeProduct.vue"
import { useHead } from "@unhead/vue";
import {
  log_view_item
} from "../plugins/analytics.js";
export default {
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  /**
   * Uses Vuex mapGetters to compute modal state and product information.
   */
  computed: {
    ...mapGetters(["productModalState", "categories","cart"]),
    isVisible() {
      return this.productModalState.isVisible;
    },
    currentProduct() {
      return this.productModalState.activeProduct;
    },

    /**
     * Constructs breadcrumb items for the product.
     * It uses the product's category IDs to find their names from the Vuex store.
     * The first element in the categories array is the full subcategory ID (including category ID),
     * and the second element is the category ID.
     *
     * @returns {Array} An array of breadcrumb objects with text and href properties.
     */
    breadcrumbItems() {
      // Initialize breadcrumbs with the home page
      let items = [
        {
          text: this.t("home"),
          href: "/",
          to: {
            name: "handla",
          },
        },
      ];

      // Check if currentProduct and its categories array are defined and not empty
      if (
        this.product &&
        this.product.categories &&
        this.product.categories.length > 0
      ) {
        

        const parentCategory = this.product.categories.find(
          (cat) => cat.parent === ""
        );
        const childCategory = this.product.categories.find(
          (cat) => cat.parent !== ""
        );

        if (parentCategory) {
          items.push({
            text: parentCategory.name,
            href: `/category/${parentCategory._id}`,
            to: {
              name: "category",
              params: {
                categoryName: parentCategory._id,
              },
            },
          });
          if (childCategory) {
            const subcategoryId = childCategory._id.split(".")[1];
            items.push({
              text: childCategory.name,
              href: `/category/${parentCategory._id}/${subcategoryId}`,
              to: {
                name: "category",
                params: {
                  categoryName: parentCategory._id,
                  subcategoryName: subcategoryId,
                },
              },
            });
          }
        }
      }

      return items;
    },

    leftButtonStyle() {
      return {
        color: this.scrolledToLeft ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 1)",
      };
    },
    rightButtonStyle() {
      return {
        color: this.scrolledToRight ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 1)",
      };
    },
  },

  /**
   * Component's local state.
   */
  data() {
    return {
      show: true,
      product: {},
      loading: true,
      relatedProducts: [], // Stores related products
      scrolledToLeft: true, // Indicates if scrolled to the far left
      scrolledToRight: false, // Indicates if scrolled to the far right
      tab: "description", // For tab control
    };
  },

  /**
   * Methods for various interactions within the modal.
   */
  methods: {
    log_view_item,
    ...mapActions(["closeProductModal", "addToCart", "removeFromCart"]),
    decline() {
      this.show = false;
      this.$router.go(-1);
    },
    /**
     * Scrolls the product row left or right based on the direction.
     * @param {Number} direction - Direction to scroll (-1 for left, 1 for right).
     */
    scroll(direction) {
      const container = this.$refs.scrollRow;
      const scrollAmount = container.clientWidth;
      container.scrollBy({
        left: direction * scrollAmount,
        behavior: "smooth",
      });
    },

    /**
     * Checks the scroll position within the product showcase.
     * Adjusts the flags based on scroll position. Considers the gap at the end of the flex container
     * to ensure accurate calculation for the right scroll button.
     */
    checkScrollPosition() {
      const container = this.$refs.scrollRow;
      // Because of that the last element in the horizontal container has a gap we will need to remove it from the total width before we can see if the user has scrolled all the way to the right.
      const gapSize = 20;
      const maxScrollLeft =
        container.scrollWidth - container.clientWidth - gapSize;
      this.scrolledToLeft = container.scrollLeft <= 0;
      this.scrolledToRight = container.scrollLeft >= maxScrollLeft;
    },

    /**
     * Fetches related products based on the current product's subcategory.
     * This function queries the API for products within the same subcategory
     * as the current product. Currently, it's set to fetch a maximum of 20 products.
     * In the future, this can be modified to use a more sophisticated related products API,
     * but for now, it simply grabs products from the same subcategory.
     */
    async fetchRelatedProducts() {
      // Check if the current product and its categories array are defined and not empty
      if (
        this.product &&
        this.product.categories &&
        this.product.categories.length > 0
      ) {
        // Extract the subcategory ID from the first element of the categories array
        const subcategory = this.product.categories[0]["_id"];

        try {
          // Request related products from the API
          const response = await CategoryProductAPI.getProducts({
            category: subcategory, // Specify the subcategory for the query
            limit: 20, // Limit the response to 20 products
          });

          // Update the relatedProducts data property with the response data
          this.relatedProducts = response.data;
        } catch (error) {
          // Log any errors that occur during the fetch operation
          console.error("Error fetching related products:", error);
        }
      }
    },
    setHead() {
      const productTitle = `${this.product.name} - NearbyStore`;

      useHead({
        title: productTitle,
        meta: [
          {
            property: "og:image",
            content:this.product.mainImage
              ? this.product.mainImage.url
              : this.product.thumbnail.url,
          },
          {
            property: "og:url",
            content: window.location.href, // Automatically use current page URL
          },
          {
            property: "og:title",
            content: productTitle, // Ensure Open Graph title matches
          },
          {
            hid: "description",
            name: "description",
            content:
              "Beställ idag på NearbyStore och få din mat levererad samma dag. Upptäck ett brett sortiment av färska råvaror och livsmedel, med bekväm hämtning nära dig. Shoppa enkelt och snabbt!",
          },
        ],
        __key: "recipeDetailHead",
      });
    },
  },

  /**
   * Vue lifecycle hook. Ensures the scroll position is checked
   * after the component is mounted and the DOM is updated.
   */
  async beforeMount() {
    
    if (this.$route.meta.dialog != undefined) {
      this.show = this.$route.meta.dialog;
    } else {
      this.show = true;
    }
  },
  watch: {
    $route: async function (newRoute, oldRoute) {
      
      
      if (newRoute.name == 'ProductDetail' && newRoute.params.id != this.product._id) {
        this.loading = true;
        const resp = await CategoryProductAPI.getProduct(newRoute.params.id);
        this.product = resp.data;
        this.setHead();
        await this.fetchRelatedProducts();
        
        this.loading = false;
      }
    },
  },
  async mounted() {
    try {
      const resp = await CategoryProductAPI.getProduct(this.$route.params.id);
      this.product = resp.data;
      this.setHead();
      await this.fetchRelatedProducts();
      
      this.log_view_item(this.product);
      this.loading = false;
    } catch (error) {
      
    }
  },

  components: {
    SaveProduct,
    ProductCard,
    ProductButton,
    Breadcrumbs,
    DiscountPrice,
    AgeRestriction,
    FruitIndividually,
    NudgeProduct
  },
};
</script>

<style scoped>
/* Modal overlay styles */
.product-tabs {
  margin-top: 20px;
}

/* Modal overlay styles */
.tab-item {
  font-size: 16px;
  background-color: transparent;
}

/* Modal overlay styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal content box styles */
.modal-content {
  position: relative;
  background: white;
  border-radius: 15px;
  padding: 20px;
  width: 80%;
  height: 90%;
  max-width: 1000px;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}

.custom5cols {
  width: 20%;
  max-width: 200px;
  min-width: 180px;
  flex-basis: 20%;
}
/* Close button styles */
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 2em;
}

.control-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 0px;
}

/* Styles for the product showcase */
.related-products {
  margin: -20px;
  padding: 20px;
  margin-top: 0px;
  padding-top: 0px;
  background-color: #ebebeb;
}

/* Styles for the product showcase */
.related-products-title {
  padding: 10px;
  font-size: 22px;
  font-weight: bold;
}

/* Styles for the scrollable product showcase */
.featured-products-container .product-showcase-row {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  gap: 20px;
  padding-bottom: 0px;
  scroll-snap-type: x mandatory;
}

/* Styles for each product item within the showcase */
.featured-products-container .product-item {
  border-radius: 10px;
  background-color: white;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  scroll-snap-align: start;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;

  /* Each container takes one forth of the space - the size of the gap / 4 */
  width: calc(100% / 4 - 15px);
}

/* When the screen is smaller than 1280px, each container takes one-third of the space */
@media (max-width: 1280px) {
  .featured-products-container .product-item {
    width: calc(100% / 3 - 15px);
  }
}

/* When the screen is smaller than 960px, each container takes half of the space */
@media (max-width: 960px) {
  .featured-products-container .product-item {
    width: calc(100% / 2 - 15px);
  }
}

.featured-products-container .product-image-container {
  flex: auto;
  display: flex;
  align-items: center;
}

.featured-products-container .product-image-container .product-image {
  width: 100%;
  height: 150px;
  margin-top: 20px;
  padding: 10px;
  object-fit: contain;
}

.featured-products-container .product-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: auto;
  padding: 10px;
  text-align: left;
}

.featured-products-container .product-name {
  color: #333;
  margin: 4px 0;
  font-size: 18px;
}

.featured-products-container .modal-product-weight {
  color: #777;
  margin: 4px 0;
}

.featured-products-container .product-price {
  font-size: 22px;
  color: #c94631;
  font-weight: bold;
  margin: 4px 0;
}

.featured-products-container .add-to-cart-btn {
  background-color: #003a70;
  color: white;
  border: none;
  width: 95%;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
  margin-top: 10px;
  height: 50px;
}

.featured-products-container .add-to-cart-btn:hover {
  background-color: #005092;
}

.offer-tag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ffd700;
  padding: 5px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
}

/* Product information container styles */
.product-information {
  margin-bottom: 20px;
  display: flex;
}

/* Product image styles */
.modal-product-image {
  flex: 0 0 40%;
  flex-direction: column;
  margin-top: 20px;
  display: flex;
  width: 290px;
  height: fit-content;
  align-items: flex-start;
  justify-content: center;
}

.modal-product-image img {
  width: 290px;
  height: 290px;
  object-fit: contain;
}

/* Product details styles */
.modal-product-details {
  text-align: left;
  padding: 20px;
  width: 100%;
}

.modal-product-weight {
  color: #333;
  text-align: left;
}

.modal-product-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.modal-product-description {
  font-size: 16px;
  margin-bottom: 20px;
}

.modal-product-price {
  font-size: 20px;
  color: #c94631;
  font-weight: bold;
  margin-bottom: 20px;
}

.modal-add-to-cart-btn {
  background-color: #003a70;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
  font-size: 22px;
  font-weight: bold;
  width: 200px;
  height: 60px;
}

.add-to-cart-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
}

.modal-product-details .item-amount-container {
  height: 60px;
  width: 200px;
}

.modal-product-details .remove-cart,
.add-cart {
  min-width: 40px;
  max-height: 40px;
  border-radius: 20px;
}

.item-amount-container {
  user-select: none;
  display: flex;
  font-size: 28px;
  align-items: center;
  justify-content: center;
  background-color: #003a700f;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 5px;
  height: 50px;
}

.item-amount {
  font-size: 18px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.remove-cart,
.add-cart {
  margin: 5px;
  min-width: 35px;
  max-height: 35px;
  border-radius: 17.5px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.remove-cart {
  border: solid;
  border-width: 1px;
  background-color: white;
}

.add-cart {
  height: 50px;
  background-color: #003a70;
  color: white;
}

/* Styles for scroll buttons */
.scroll-btn {
  width: 45px;
  height: 30px;
  position: relative;
  background-color: white;
  font-weight: bold;
  color: black;
  border: none;
  cursor: pointer;
  z-index: 2;
}

.scroll-left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: 2px solid black;
}

.scroll-right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-left: 2px solid black;
}

.nutrition-table th,
.nutrition-table td {
  border: 1px solid #e0e0e0; /* Light grey border for cells */
  padding: 8px; /* Slight padding for content inside cells */
  min-width: 200px;
}

.nutrition-table th {
  background-color: #f5f5f5; /* Light grey background for header */
  font-weight: bold; /* Make header text bold */
}

.nutrition-table tbody tr:nth-child(even) {
  background-color: #fafafa; /* Zebra-striping for table rows */
}

.breadcrumbs-items {
  text-align: center;
}

@media (max-width: 1024px) {
  .product-information {
    flex-direction: column;
  }

  .add-to-cart-container {
    padding-right: 20px;
  }
}

/* Phone styles*/

@media (max-width: 425px) {
  .product-information {
    flex-direction: column;
  }

  /* Styles for each product item within the showcase */
  .featured-products-container .product-item {
    width: 100%;
  }

  .button-container {
    display: flex;
  }

  /* Product image styles */
  .modal-product-image {
    width: 100%;
    align-items: center;
  }

  .modal-product-image img {
    width: 225px;
    height: 225px;
  }

  .modal-content {
    position: fixed;
    border-radius: 0px;
    width: 100vw;
    height: 100vh;
    max-width: none;
    max-height: none;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .nutrition-table th,
  .nutrition-table td {
    min-width: 100px;
  }

  .featured-products-container {
    margin-bottom: 80px;
  }
}
</style>
