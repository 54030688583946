// BannerAPI.js
import axiosInstance from "./AxiosInstance";
import store from "@/store"; // Import the Vuex store
/**
 * Banner API for interacting with banner-related endpoints.
 */
const BannerAPI = {
  /**
   * Retrieves all banners, optionally filtered by image and text banners.
   *
   * @param {Object} options - Options for filtering the banners.
   * @param {boolean} [options.imageBanner=true] - Flag to include image banners.
   * @param {boolean} [options.textBanner=true] - Flag to include text banners.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the banners.
   */
  getAllBanners: ({ imageBanner = true, textBanner = true } = {}) => {
    const params = new URLSearchParams();
    if (store.state.cart != null && store.state.cart.pickupPoint != null) {
      params.append("pickupPoint", store.state.cart.pickupPoint._id);
    }
    params.append("imageBanner", imageBanner);
    params.append("textBanner", textBanner);
    const config = {
      params,
    };
    return axiosInstance.get("/banner", config);
  },

  /**
   * Retrieves a specific banner by its ID.
   *
   * @param {string} bannerId - The ID of the banner to retrieve.
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the banner.
   */
  getBannerById: (bannerId) => {
    return axiosInstance.get(`/banner/${bannerId}`);
  },
};

export default BannerAPI;
