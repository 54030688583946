<template>
  <v-row class="pt-1 mt-1">
    <v-col
      cols="3"
      align="center"
      justify="center"
      @click="handleProductClick(product)"
      class="hover"
      style="position: relative"
    >
      <DiscountPrice
        v-if="product.promotion"
        style="position: absolute; top: 3px; right: 0px; z-index: 1000"
        :scaleFactor="0.5"
        :promotion="product.promotion"
        :isWeight="product.weightProduct"
      />
      <v-img
        :src="product.productVariant.thumbnail.url"
        :alt="product.productVariant.thumbnail.alt"
        alt="Product Image"
        height="70"
      />
    </v-col>
    <v-col align="space-between">
      <v-row class="pa-2 pt-1">
        <v-col class="pa-0">
          <v-col sm="12" class="pa-0">
            <p class="bold-1">
              {{ product.productVariant.name }}
            </p></v-col
          >
        </v-col>
      </v-row>
      <v-row class="pl-2 pr-3 mt-2 mb-1" align="end">
        <v-col class="pa-0" justify="center">
          <v-col sm="12" class="pa-0 pr-1">
            <p class="body-4">
              {{
                product.productVariant.manufacturer +
                " " +
                product.productVariant.displayWeight
              }}
            </p></v-col
          >
          <v-col class="pa-0">
            <p class="bold-1">
              <span class=""> {{ product.productVariant.price }}</span>
              {{
                "/" +
                (product.productVariant.weightProduct
                  ? "kg"
                  : $t("piece_abbreviation"))
              }}
            </p>
          </v-col></v-col
        >
        <v-col class="pa-0" cols="5" align="end">
          <ProductButton
            :product="product"
            :cartProduct="true"
            :shoppingId="shoppingId"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import ProductButton from "../ProductButton.vue";
import DiscountPrice from ".././DiscountPrice.vue";
export default {
  components: {
    ProductButton,
    DiscountPrice,
  },
  computed: {},
  props: {
    product: {
      type: Object,
      required: true, // Default to plus icon
    },
    shoppingId: {
      type: String,
      required: true, // Default to plus icon
    },
  },
};
</script>
