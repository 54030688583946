import { reactive } from "vue";
import { io } from "socket.io-client";
import store from "./store";

export const state = reactive({
  connected: false,
  fooEvents: [],
  barEvents: [],
});

// "undefined" means the URL will be computed from the `window.location` object
const URL =
  process.env.VITE_APP_ENVIROMENT === "production"
    ? process.env.VITE_APP_WEBSOCKET
    : "http://localhost:4000";

export const socket = io(URL);

socket.on("connect", () => {
  console.log("SOCKET CONNECTED");
  state.connected = true;
  if (
    store.state.user != null &&
    store.state.user._id != null &&
    store.state.user._id != ""
  ) {
    socket.emit("register", store.state.user._id);
  }
});

socket.on("disconnect", () => {
  console.log("SOCKET DISCONNECTED");
  state.connected = false;
});

socket.on("reconnect", () => {
  console.log("SOCKET RECONNECTED");
  state.connected = false;
});
socket.on("orderUpdated", (...args) => {
  state.fooEvents.push(args);
  if (args[0]) {
    store.dispatch("updateOrder", args[0]);
  }
});

socket.on("userFetch", () => {
  console.log("GETUSER");
  store.dispatch("getUser");
});

socket.on("orderFetch", (...args) => {
  if (args[0]) {
    store.dispatch("fetchOrder", args[0]);
  }
  state.barEvents.push(args);
});
