<template>
  <v-navigation-drawer
    v-model="locationModalIsVisible"
    temporary
    location="right"
    :width="$vuetify.display.xs || $vuetify.display.sm ? 0 : 380"
    :style="!$vuetify.display.xs ? 'width: 380px' : 'width: 100%'"
  >
    <v-card height="100%" class="pt-5" style="overflow-y: auto">
      <v-row>
        <v-col class="pa-4">
          <v-row
            align="start"
            :justify="locationStep > 1 ? 'space-between' : 'end'"
            class="pa-0"
          >
            <v-btn
              v-if="locationStep > 1"
              class="ma-2"
              elevation="0"
              @click="goBack"
              icon="mdi-arrow-left"
              color="surface"
              size="small"
              aria-label="Vänster"
            ></v-btn>
            <v-btn
              class="ma-2"
              elevation="0"
              @click="openLocationSelection"
              icon="mdi-close"
              color="surface"
              size="small"
              aria-label="Stäng"
            ></v-btn>
          </v-row>
          <v-window disabled v-model="locationStep">
            <v-window-item disabled :value="1">
              <v-col class="pa-0" align="center">
                <p class="bold-h3">{{ $t("pickup_locations") }}</p>
                <v-row align="center" justify="center" class="mt-4">
                  <v-col cols="10" class="pa-0">
                    <searchAddress @updateCoords="updateCoords" />
                  </v-col>
                </v-row>

                <v-row cols="12" class="pa-2">
                  <v-col cols="12" class="pa-2">
                    <v-card
                      @click="selectHomeDelivery()"
                      style="height: 100%"
                      class="pa-2 locker"
                      elevation="0"
                      :style="{
                        border:
                          cart.pickupAddress != null
                            ? '2px solid #4CAF50'
                            : '2px solid grey',
                      }"
                    >
                      <v-col align="start" class="pa-0">
                        <v-img
                          :src="truck"
                          contain
                          alt="truck"
                          height="150"
                        ></v-img>

                        <p class="bold-h4">{{ $t("home_delivery") }}</p>
                        <p
                          class="pt-1"
                          v-if="
                            helpTextPostalCode != '' &&
                            cart.pickupAddress == null
                          "
                          :style="
                            errorPostalCode ? 'color:#C94631' : 'color:#4CAF50'
                          "
                        >
                          {{ helpTextPostalCode }}
                        </p>
                        <p class="body-2" v-if="cart.pickupAddress">
                          {{ cart.pickupAddress.address }}
                        </p>
                      </v-col>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="pa-2"
                    v-for="location in pickupPoints"
                    :key="location._id"
                  >
                    <v-card
                      @click="selectPickupLocation(location._id)"
                      style="height: 100%"
                      class="pa-2 locker"
                      elevation="0"
                      :style="{
                        border:
                          cart.pickupPoint != null &&
                          cart.pickupPoint._id === location._id
                            ? '2px solid #4CAF50'
                            : '2px solid grey',
                      }"
                    >
                      <v-col class="pa-0" align="start">
                        <v-col
                          cols="12"
                          align="center"
                          justify="center"
                          class="pa-0"
                        >
                          <v-img :src="locker" contain alt="ns-locker"></v-img>
                        </v-col>
                        <p class="bold-h4">
                          {{ location.name }}
                        </p>
                        <p class="body-2 pt-2">{{ location.address }}</p>
                        <p v-if="lat > 0" class="body-2">
                          Avstånd:
                          {{ writeDistance(location.distanceInMeters) }}
                        </p>
                      </v-col>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-window-item>
            <v-window-item disabled :value="2">
              <v-col class="pa-0" align="center">
                <p class="bold-h3">
                  {{
                    cart.pickupAddress
                      ? $t("home_delivery")
                      : $t("pickup_times")
                  }}
                </p>

                <span
                  class="hover bold-h4 ml-2"
                  style="color: #003a70"
                  @click="locationStep = 1"
                  v-if="cart.pickupPoint"
                >
                  {{ cart.pickupPoint.name }}
                </span>
                <span
                  class="hover bold-h4 ml-2"
                  style="color: #003a70"
                  @click="locationStep = 3"
                  v-if="cart.pickupAddress"
                >
                  {{
                    cart.pickupAddress.address +
                    ", " +
                    cart.pickupAddress.postalCode
                  }}
                </span>
                <v-progress-circular
                  v-if="pickupTimesLoading"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-col v-else class="pa-0 mt-2">
                  <v-window v-model="window" show-arrows>
                    <template v-slot:prev="{ props }">
                      <v-btn size="large" @click="props.onClick" variant="text">
                        <v-icon size="30">mdi-chevron-left</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:next="{ props }">
                      <v-btn size="large" @click="props.onClick" variant="text">
                        <v-icon size="30">mdi-chevron-right</v-icon>
                      </v-btn>
                    </template>
                    <v-window-item
                      v-for="(date, index) in next14Days"
                      :key="index"
                      :value="index"
                    >
                      <v-card
                        elevation="0"
                        class="d-flex justify-center align-center"
                      >
                        <span class="bold-h4 mb-3">
                          {{ getDayLabel(date, true) }}</span
                        >
                      </v-card>
                    </v-window-item>
                  </v-window>
                  <div v-for="(date, index) in next14Days">
                    <v-col v-if="window === index">
                      <v-row class="mt-2">
                        <v-col
                          v-if="
                            pickupTimes.filter(
                              (element) =>
                                isSameDate(element.orderBefore, date) === true
                            ).length > 0
                          "
                          cols="6"
                          sm="6"
                          class="pa-1"
                          v-for="(time, index) in pickupTimes.filter(
                            (element) =>
                              isSameDate(element.orderBefore, date) === true
                          )"
                        >
                          <v-card
                            :ripple="time.lockersLeft > 0 == true"
                            @click="choosePickupTime(time)"
                            :class="
                              time.lockersLeft > 0 == true
                                ? 'pa-2'
                                : 'pa-2 no-hover'
                            "
                            elevation="0"
                            :style="{
                              border:
                                cart.pickupTime != null &&
                                cart.pickupTime._id === time._id
                                  ? '2px solid #4CAF50'
                                  : '2px solid grey',
                              backgroundColor:
                                time.lockersLeft > 0 == true
                                  ? 'transparent'
                                  : 'grey',
                            }"
                          >
                            <v-card
                              v-if="
                                time.lockersLeft < 4 == true &&
                                time.lockersLeft !== 0
                              "
                              style="
                                position: absolute;
                                right: -38px;
                                top: 3px;
                                z-index: 1000;
                                width: 120px;
                                transform: translate(0%, 0%) rotate(40deg);
                              "
                              color="yellow"
                              class="ma-1"
                              flat
                            >
                              <v-col
                                class="pt-1 pb-1 pa-0"
                                align="center"
                                justify="center"
                                ><p class="bold-1">
                                  {{ $t("low_stock") }}
                                </p></v-col
                              >
                            </v-card>
                            <v-card
                              v-if="time.lockersLeft > 0 == false"
                              style="
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%) rotate(-15deg);
                                z-index: 1000;
                                min-width: 120px;
                              "
                              elevation="0"
                              color="error"
                              class="pa-2"
                              ><p class="pb-1 bold-h4">
                                {{ $t("lockers_full") }}
                              </p></v-card
                            >

                            <v-col class="pb-4">
                              <p class="body-3">
                                {{
                                  time.homeDelivery != null && time.homeDelivery
                                    ? $t("delivery_between")
                                    : $t("pickup")
                                }}
                              </p>
                              <p class="bold-h4 pt-2">
                                {{
                                  formatTime(time.pickupAt) +
                                  " - " +
                                  formatTime(time.pickupBefore)
                                }}
                              </p>
                              <p class="body-3 pt-2">
                                {{ time.price.toFixed(2) + " kr" }}
                              </p>
                              <p class="font-italic body-3">
                                {{
                                  (time.homeDelivery != null &&
                                  time.homeDelivery
                                    ? $t("locations_left")
                                    : $t("lockers_left")) +
                                  " " +
                                  time.lockersLeft
                                }}
                              </p>
                              <p class="font-italic body-3">
                                {{
                                  $t("order_by") +
                                  " " +
                                  formatTime(time.orderBefore)
                                }}
                              </p>
                            </v-col>
                          </v-card>
                        </v-col>
                        <v-col v-else> {{ $t("no_available_times") }}</v-col>
                      </v-row>
                    </v-col>
                  </div>
                </v-col>
              </v-col>
            </v-window-item>
            <v-window-item disabled :value="3">
              <v-col class="pa-0" align="center">
                <p class="bold-h3">{{ $t("addresses") }}</p>
                <v-col v-if="user != null && user.shippingAddresses.length > 0">
                  <v-card
                    v-for="address in user.shippingAddresses"
                    class="mt-2"
                    elevation="0"
                    :color="
                      cart.pickupAddress != null &&
                      cart.pickupAddress.address == address.address
                        ? 'primary'
                        : 'background'
                    "
                    @click="selectPickupAddress(address)"
                  >
                    <v-row align="center" class="pa-6" justify="space-between">
                      <v-col class="pa-0" align="start">
                        <p class="bold-h4 pb-2">{{ address.address }}</p>
                        <p class="body-2">
                          {{ address.postalCode + " " + address.city }}
                        </p>
                      </v-col>
                      <v-col class="pa-0" align="end">
                        <v-menu location="bottom" v-if="user">
                          <template v-slot:activator="{ props }">
                            <v-btn
                              icon="mdi-dots-vertical"
                              flat
                              variant="text"
                              @click.stop=""
                              v-bind="props"
                            ></v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="">
                              <v-list-item-title
                                class="bold-1 pb-2"
                                style="color: #003a70"
                                @click="startChangeAddress(address)"
                                >{{ $t("change") }}</v-list-item-title
                              >
                            </v-list-item>
                            <v-list-item @click="removeAddress(address)">
                              <v-list-item-title
                                class="bold-1 pb-2"
                                style="color: #c94631"
                                >{{ $t("delete") }}</v-list-item-title
                              >
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col v-else>
                  <p class="body-2">
                    {{ $t("no_addresses") }}
                  </p>
                </v-col>
                <v-col>
                  <v-btn
                    @click="locationStep = 4"
                    class="button text-none pr-4"
                    block
                    elevation="0"
                    color="primary"
                    >{{ $t("add_address") }}</v-btn
                  ></v-col
                >
              </v-col>
            </v-window-item>
            <v-window-item disabled :value="4">
              <v-col class="pa-0 pb-10" align="center">
                <p class="bold-h3">{{ $t("add_address") }}</p>
                <v-row align="center" justify="center" class="mt-4">
                  <v-col cols="10" class="pa-0" align="start">
                    <searchAddress
                      @updateCoords="updateAddress"
                      :address="{ ...address }"
                    />
                    <v-card
                      v-if="errorPostalCode || helpTextPostalCode != ''"
                      elevation="0"
                      class="mt-2"
                      :color="errorPostalCode ? 'error' : 'success'"
                      ><v-col
                        ><v-row class="pa-4" align="center"
                          ><v-icon>mdi-information</v-icon>
                          <v-col cols="10" class="pa-0"
                            ><p class="pl-4 mb-1 bold-h4">
                              {{ helpTextPostalCode }}
                            </p></v-col
                          >
                        </v-row>
                        <v-btn
                          v-if="suggestLocation"
                          @click="suggestPlace"
                          block
                          color="surface"
                          variant="outlined"
                          elevation="0"
                          class="mt-2"
                          >{{ $t("suggest_place") }}</v-btn
                        >
                      </v-col>
                    </v-card>
                    <div
                      v-if="
                        address.address != '' &&
                        !errorPostalCode &&
                        !loadingUpdateAddress
                      "
                      class="mt-4"
                    >
                      <p class="bold-h4">
                        {{ $t("type_of_place") }}
                      </p>
                      <v-item-group
                        selected-class="bg-primary"
                        v-model="typeIndex"
                        mandatory
                      >
                        <v-container class="pa-0 mt-2">
                          <v-col
                            v-for="type in types"
                            :key="type"
                            cols="12"
                            class="pa-0"
                          >
                            <v-item
                              v-slot="{ isSelected, selectedClass, toggle }"
                              :key="type"
                            >
                              <v-card
                                :class="[selectedClass]"
                                color="background"
                                elevation="0"
                                @click="toggle"
                                class="pl-2 pr-2"
                              >
                                <v-row class="pa-4" align="center">
                                  <font-awesome-icon
                                    :icon="getTypeIcon(type)"
                                  />
                                  <p class="bold-h4 pa-4 mb-2">
                                    {{ $t(type) }}
                                  </p>
                                </v-row>
                              </v-card>
                            </v-item>
                          </v-col>
                        </v-container>
                      </v-item-group>
                      <div v-if="address.type != ''">
                        <p class="bold-h4 pb-4 mt-4">
                          {{ $t("address_details") }}
                        </p>
                        <v-text-field
                          v-if="address.type != 'other'"
                          v-model="address.doorCode"
                          clearable
                          color="primary"
                          bg-color="surface"
                          variant="outlined"
                          single-line
                          hide-details
                          :placeholder="
                            address.type == 'house'
                              ? $t('name_or_number_on_door')
                              : $t('entry_code_or_intercom')
                          "
                          persistent-hint
                        ></v-text-field>
                        <v-switch
                          v-if="
                            address.type == 'apartment' ||
                            address.type == 'office'
                          "
                          v-model="address.doorOpen"
                          :label="$t('door_is_open')"
                          inset
                          hide-details
                          color="primary"
                        ></v-switch>
                        <p
                          v-if="errorDoorCode != ''"
                          class="body-2 pb-2"
                          style="color: #c94631"
                        >
                          {{ errorDoorCode }}
                        </p>
                        <v-text-field
                          v-if="address.type == 'apartment'"
                          v-model="address.nameOnDoor"
                          clearable
                          color="primary"
                          bg-color="surface"
                          variant="outlined"
                          single-line
                          hide-details
                          :placeholder="$t('name_or_number_on_door')"
                          persistent-hint
                        ></v-text-field>

                        <v-text-field
                          v-if="
                            address.type == 'apartment' ||
                            address.type == 'office'
                          "
                          class="mt-4"
                          v-model="address.floor"
                          clearable
                          color="primary"
                          bg-color="surface"
                          variant="outlined"
                          single-line
                          hide-details
                          :placeholder="$t('floor')"
                          persistent-hint
                        ></v-text-field>
                        <v-textarea
                          class="mt-4"
                          v-model="address.deliveryInstructions"
                          clearable
                          color="primary"
                          rows="2"
                          bg-color="surface"
                          variant="outlined"
                          single-line
                          hide-details
                          :placeholder="$t('delivery_instructions')"
                          persistent-hint
                        ></v-textarea>
                        <p class="bold-h4 pt-4">{{ $t("enter_entry") }}</p>

                        <div style="position: relative" class="mt-4">
                          <GMapMap
                            :center="center"
                            :zoom="18"
                            :options="options2"
                            ref="mapRef2"
                            :draggable="false"
                            style="
                              height: 200px;
                              margin-top: 0px;
                              border-radius: 10px;
                            "
                          >
                          </GMapMap>
                          <div
                            style="
                              position: absolute;
                              top: 48%;
                              left: 50%;
                              transform: translate(-50%, -50%);
                              z-index: 1000;
                            "
                          >
                            <font-awesome-icon
                              size="2xl"
                              style="color: #003a70"
                              icon="fa-solid fa-location-pin"
                            />
                          </div>
                        </div>
                        <v-btn
                          @click="showMap = true"
                          class="mt-4 button text-none"
                          block
                          color="third"
                          variant="outlined"
                          elevation="0"
                          >{{ $t("exact_entrance") }}</v-btn
                        >

                        <v-dialog
                          v-model="showMap"
                          @click:outside="showMap = false"
                          width="700"
                          height="600"
                        >
                          <v-card>
                            <v-col class="pa-4">
                              <v-row class="pa-0" align="center">
                                <v-btn
                                  icon="mdi-close"
                                  flat
                                  @click="showMap = false"
                                ></v-btn>
                                <p class="bold-h3 pb-2 pl-2">
                                  {{ address.address }}
                                </p></v-row
                              >
                            </v-col>
                            <div>
                              <v-col
                                style="
                                  position: absolute;
                                  bottom: 5px;
                                  left: 5px;
                                  z-index: 1000;
                                "
                              >
                                <v-row align="center" justify="center">
                                  <v-col cols="10">
                                    <v-btn
                                      block
                                      class="text-none"
                                      color="primary"
                                      @click="doneEditingPostion"
                                      >{{ $t("done") }}</v-btn
                                    ></v-col
                                  ></v-row
                                ></v-col
                              >
                              <div style="position: relative">
                                <GMapMap
                                  :center="center"
                                  :zoom="18"
                                  :options="options"
                                  ref="mapRef"
                                  :draggable="true"
                                  style="
                                    height: 540px;
                                    margin-top: 0px;
                                    border-radius: 10px;
                                  "
                                >
                                </GMapMap>
                                <div
                                  style="
                                    position: absolute;
                                    top: 48%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    z-index: 1000;
                                  "
                                >
                                  <font-awesome-icon
                                    size="2xl"
                                    style="color: #003a70"
                                    icon="fa-solid fa-location-pin"
                                  />
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </v-dialog>

                        <v-btn
                          class="mt-4 button text-none"
                          color="primary"
                          block
                          :loading="loadingSaveAddress"
                          :disabled="loadingSaveAddress"
                          @click="saveAddress"
                          >{{ $t("save_address") }}</v-btn
                        >
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
      <v-col class="continue pa-2 mt-2 pl-8 pr-8" v-if="locationStep == 1">
        <v-btn
          block
          class="pa-2 xl-button text-none"
          color="primary"
          @click="seeAllLocations"
        >
          {{ $t("see-all-pickup-locations") }}
        </v-btn>
      </v-col>
    </v-card>
  </v-navigation-drawer>
  <LoginModal
    :visible="loginModal"
    @close="loginModal = false"
    @done="loginDone"
  ></LoginModal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import locker from "../../assets/images/ns-locker.png";
import truck from "../../assets/images/storLastbil.png";
import PickupTimeAPI from "@/api/PickupTimeAPI"; // Import the UserAPI
import { formatTime, getDayLabel } from "../../utils.js";
import PickupPointAPI from "@/api/PickupPointAPI";
import { useGeolocation } from "@vueuse/core";
import searchAddress from "../searchAddress.vue";
import CartAPI from "@/api/CartAPI";
import axios from "axios";
import { useI18n } from "vue-i18n";
export default {
  components: {
    searchAddress,
  },
  setup() {
    const { coords, locatedAt, error, resume, pause } = useGeolocation({
      immediate: false, // Prevents immediate triggering
    });
    const { t, locale } = useI18n();
    return { coords, locatedAt, error, resume, pause, t, locale };
  },

  computed: {
    ...mapFields(["locationModalIsVisible", "cart", "user", "locationStep"]),
  },
  watch: {
    typeIndex(newVal, oldVal) {
      if (newVal != null) {
        this.address.type = this.types[newVal];
      }
    },
    async locationModalIsVisible(newVal, oldVal) {
      console.log("locationModalIsVisible", newVal, oldVal);
      console.log("locationStep", this.locationStep);
      if (newVal == true) {
        if (
          (this.cart.pickupPoint != null || this.cart.pickupAddress != null) &&
          this.locationStep == 1
        ) {
          this.locationStep = 2;

          this.fetchPickupTimes();
          if (this.cart.pickupTime != null) {
            this.window = this.calculateActualDaysFromToday(
              this.cart.pickupTime.orderBefore
            );
          }
        }
        const resp = await PickupPointAPI.getAllPickupPoints({
          lat: this.lat,
          lng: this.lng,
        });
        this.pickupPoints = resp.data;
      }
    },
  },

  async mounted() {
    const resp = await PickupPointAPI.getAllPickupPoints({
      lat: this.lat,
      lng: this.lng,
    });
    this.pickupPoints = resp.data;
    if (this.cart.pickupPoint != null || this.cart.pickupAddress != null) {
      this.fetchPickupTimes();
    }
  },
  created() {
    this.generateNext14Days();
  },
  methods: {
    ...mapActions([
      "selectLocation",
      "toggleLocationModal",
      "updateSelectedPickupTime",
      "addUserAddress",
      "moveCart",
      "changePickupTime",
      "removeUserAddress",
      "changeUserAddress",
    ]),
    formatTime,
    getDayLabel,
    goBack() {
      this.locationStep = 1;
      this.errorPostalCode = false;
      this.suggestLocation = false;
      this.helpTextPostalCode = "";
    },

    async startChangeAddress(address) {
      this.center = {
        lat: address.position.coordinates[1],
        lng: address.position.coordinates[0],
      };
      this.typeIndex = this.types.indexOf(address.type);
      this.address = address;
      this.address.coordinates = { lat: 200, lng: 200 };
      this.locationStep = 4;
    },
    async suggestPlace() {
      const address =
        this.address.address +
        " " +
        this.address.postalCode +
        " " +
        this.address.city;
      console.log("suggestPlace", address);
      console.log("user", this.user.email);
      await PickupPointAPI.suggestLocation(this.user.email, address);
    },
    async removeAddress(address) {
      console.log("ADDRESS");
      console.log(this.cart.pickupAddress.address, address);
      if (
        this.cart.pickupAddress != null &&
        this.cart.pickupAddress.address == address.address &&
        this.cart.pickupAddress.postalCode == address.postalCode
      ) {
        await this.moveCart({});
      }
      await this.removeUserAddress(address._id);
      this.user.shippingAddresses = this.user.shippingAddresses.filter(
        (address) => address._id !== addressId
      );
    },
    async fetchPickupTimes() {
      this.pickupTimes = [];
      this.pickupTimesLoading;
      this.locationStep = 2;
      const response = await PickupTimeAPI.getAllPickupTimes(
        this.cart.pickupPoint ? this.cart.pickupPoint._id : "",
        this.cart.pickupAddress ? this.cart.pickupAddress.postalCode : ""
      );
      this.generateNext14Days();
      this.window = 0;
      this.pickupTimes = response.data;
      this.pickupTimesLoading = false;
    },
    calculateActualDaysFromToday(date) {
      const today = new Date();
      const orderBefore = new Date(date);

      // Reset time to midnight for both dates to focus only on the day comparison
      today.setHours(0, 0, 0, 0);
      orderBefore.setHours(0, 0, 0, 0);

      // Calculate the time difference in milliseconds
      const timeDiff = orderBefore.getTime() - today.getTime();

      // Convert the time difference from milliseconds to days
      const dayDiff = Math.round(timeDiff / (1000 * 3600 * 24));

      return dayDiff;
    },
    seeAllLocations() {
      this.openLocationSelection();
      this.$router.push({
        name: "pickupPoints",
      });
    },
    getTypeIcon(type) {
      switch (type) {
        case "house":
          return "fa-solid fa-house";

        case "apartment":
          return "fa-solid fa-building";

        case "office":
          return "fa-solid fa-briefcase";

        case "other":
          return "fa-solid fa-mountain-city";

        default:
          return "fa-solid fa-house";
      }
    },
    async saveAddress() {
      try {
        this.loadingSaveAddress = true;
        if (
          this.address.type != "other" &&
          this.address.doorCode == "" &&
          this.address.doorOpen == false
        ) {
          this.errorDoorCode =
            this.address.type == "house"
              ? this.t("name_or_number_required")
              : this.t("entry_code_required");
          this.loadingSaveAddress = false;
          return;
        }

        if (this.address.type == "apartment" && this.address.nameOnDoor == "") {
          this.errorDoorCode = this.t("name_or_number_required");
          this.loadingSaveAddress = false;
          return;
        }
        const { _id, coordinates, ...rest } = this.address;
        console.log("this.address", this.address);
        if (this.address._id) {
          await this.changeUserAddress({ addressId: _id, address: rest });
        } else {
          await this.addUserAddress(rest);
        }

        await this.selectPickupAddress(this.address);
        this.address = {
          address: "",
          position: {
            type: "Point",
            coordinates: [0, 0],
          },
          postalCode: "",
          city: "",
          country: "",
          title: "",
          nameOnDoor: "",
          floor: "",
          type: "",
          doorCode: "",
          deliveryInstructions: "",
          doorOpen: false,
        };
        this.loadingSaveAddress = false;
      } catch (error) {
        this.loadingSaveAddress = false;
        if (axios.isAxiosError(error)) {
          // Extract the reason from the error response
          const reason = error.response?.data?.reason || "An error occurred";

          this.$store.dispatch("vtoast/showToast", {
            message: this.t(reason),
            color: "error",
            timer: 5000,
          });
        }
      }
    },
    async updateCoords(lat, lng, address, city, country, postalCode) {
      this.lat = lat;
      this.lng = lng;
      const resp = await PickupPointAPI.getAllPickupPoints({
        lat: lat,
        lng: lng,
      });

      this.pickupPoints = resp.data;
      try {
        console.log("POSTALCODE", postalCode);
        if (postalCode == "" || postalCode == null) {
          this.errorPostalCode = true;
          this.suggestLocation = false;
          this.helpTextPostalCode = this.t("enter_house");
          return;
        }
        await CartAPI.verifyPostalCode(postalCode);
        this.errorPostalCode = false;
        this.suggestLocation = false;
        this.helpTextPostalCode = this.t("we_deliver_to_this_address");
      } catch (error) {
        if (axios.isAxiosError(error)) {
          // Extract the reason from the error response
          const reason = error.response?.data?.reason || "An error occurred";
          this.errorPostalCode = true;
          this.suggestLocation = true;
          this.helpTextPostalCode = this.t(reason);
        }
      }
    },
    writeDistance(distanceInM) {
      if (distanceInM < 100) {
        return "Under 100m";
      } else if (distanceInM < 1000) {
        return distanceInM.toFixed(0) + "m";
      } else {
        return (distanceInM / 1000).toFixed(1) + " km";
      }
    },
    generateNext14Days() {
      const days = [];
      const today = new Date();
      for (let i = 0; i < 7; i++) {
        const nextDay = new Date(today);
        nextDay.setDate(today.getDate() + i);
        days.push(nextDay);
      }
      this.next14Days = days;
    },

    isSameDate(inDate, checkDate) {
      const date = new Date(inDate);
      const today = new Date(checkDate);

      // Remove time component from both dates to compare only the date part
      const todayDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      const inputDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      const diffTime = inputDate - todayDate;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays === 0) {
        return true; // "Today" in Swedish
      } else {
        false;
      }
    },
    async choosePickupTime(pickupTime) {
      if (pickupTime.lockersLeft > 0 == true) {
        await this.changePickupTime(pickupTime);
        this.toggleLocationModal();
      }
    },

    openLocationSelection() {
      this.toggleLocationModal();
    },
    /**
     * Selects a pickup location.
     * @param {string} locationId - The ID of the location to select.
     */

    async doneEditingPostion() {
      const mapInstance = this.$refs.mapRef.$mapObject;
      if (mapInstance) {
        const center = mapInstance.getCenter();
        this.address.position.coordinates = [center.lng(), center.lat()];
        this.showMap = false;
        const mapInstance2 = this.$refs.mapRef2.$mapObject;
        if (mapInstance2) {
          mapInstance2.setCenter(
            new google.maps.LatLng(center.lat(), center.lng())
          );
          mapInstance2.setZoom(18);
        }
      }
    },

    async updateAddress(lat, lng, address, city, country, postalCode) {
      try {
        this.loadingUpdateAddress = true;
        this.address.address = address;

        this.address.postalCode = postalCode;
        this.address.city = city;
        this.address.country = country;

        this.address.position.coordinates = [lng, lat];
        console.log("POSTALCODE", postalCode);
        if (postalCode == "" || postalCode == null) {
          this.errorPostalCode = true;
          this.suggestLocation = false;
          this.helpTextPostalCode = this.t("enter_house");
          return;
        }
        await CartAPI.verifyPostalCode(postalCode);
        this.errorPostalCode = false;
        this.suggestLocation = false;
        this.helpTextPostalCode = this.t("we_deliver_to_this_address");
        this.center = { lat: lat, lng: lng };
        this.loadingUpdateAddress = false;
        const mapInstance = this.$refs.mapRef2.$mapObject;
        if (mapInstance) {
          mapInstance.setCenter(new google.maps.LatLng(lat, lng));
          mapInstance.setZoom(18);
        }
      } catch (error) {
        console.log(error);
        this.loadingUpdateAddress = false;
        if (axios.isAxiosError(error)) {
          // Extract the reason from the error response
          const reason = error.response?.data?.reason || "An error occurred";
          this.errorPostalCode = true;
          this.suggestLocation = true;
          this.helpTextPostalCode = this.t(reason);
        }
      }
    },

    async selectPickupAddress(address) {
      try {
        const { _id, coordinates, ...rest } = address;
        await this.moveCart({
          pickupAddress: rest,
        });
        this.pickupTimesLoading = true;
        this.locationStep = 2;

        const response = await PickupTimeAPI.getAllPickupTimes(
          "",
          address.postalCode
        );
        this.generateNext14Days();
        this.window = 0;
        this.pickupTimes = response.data;
        this.pickupTimesLoading = false;
      } catch (error) {}
    },
    loginDone() {
      this.loginModal = false;
      console.log("this.user", this.user);
      if (this.user) {
        this.selectHomeDelivery();
      }
    },
    async selectHomeDelivery() {
      if (!this.user) {
        // Store the intended route and redirect to login
        this.loginModal = true;
        return;
      }
      this.errorPostalCode = false;
      this.suggestLocation = false;
      this.helpTextPostalCode = "";

      if (
        this.user.shippingAddresses != null &&
        this.user.shippingAddresses.length > 0
      ) {
        if (this.cart.pickupAddress) {
          this.fetchPickupTimes();
        } else {
          this.locationStep = 3;
        }
      } else {
        this.address = {
          address: "",
          position: {
            type: "Point",
            coordinates: [0, 0],
          },
          postalCode: "",
          city: "",
          country: "",
          nameOnDoor: "",
          title: "",
          floor: "",
          type: "",
          doorCode: "",
          deliveryInstructions: "",
          doorOpen: false,
        };
        this.locationStep = 4;
      }
    },
    async selectPickupLocation(locationId) {
      await this.moveCart({
        pickupPoint: locationId,
      });
      this.fetchPickupTimes();
    },
  },
  data() {
    return {
      scrolledToLeft: true,
      scrolledToRight: false,
      pickupTimes: {},
      pickupPoints: [],
      pickupTimesLoading: true,
      loadingUpdateAddress: false,
      model: null,
      next14Days: [],
      lng: 0,
      lat: 0,
      loginModal: false,
      showMap: false,
      activeTab: null,
      length: 3,
      types: ["house", "apartment", "office", "other"],
      locker: locker,
      truck: truck,
      window: 0,
      errorPostalCode: false,
      errorDoorCode: "",
      helpTextPostalCode: "",
      typeIndex: null,
      options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: true,
        draggable: true,
        fullscreenControl: false,
        clickableIcons: false,
      },
      options2: {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        draggable: false,
        fullscreenControl: false,
        clickableIcons: false,
      },
      center: { lat: 58.407756, lng: 15.596989 },
      loadingSaveAddress: false,
      address: {
        address: "",
        position: {
          type: "Point",
          coordinates: [0, 0],
        },
        coordinates: { lat: 200, lng: 200 },
        postalCode: "",
        nameOnDoor: "",
        city: "",
        country: "",
        title: "",
        floor: "",
        type: "",
        doorCode: "",
        deliveryInstructions: "",
        doorOpen: false,
        suggestLocation: false,
      },
    };
  },
};
</script>

<style>
.no-hover {
  cursor: auto;
}
.hover:hover {
  cursor: pointer;
  text-decoration: underline;
}
.continue {
  position: sticky;
  bottom: 10px;
  z-index: 100;
}
</style>
