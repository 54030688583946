<template>
  <div
    class="heart-container"
    :style="{
      width: small ? '60px' : '100px',
      height: small ? '60px' : '100px',
    }"
  >
    <v-icon
      class="heart-icon"
      :style="{ 'font-size': small ? '85px' : '140px' }"
      color="yellow"
      >mdi-heart</v-icon
    >
    <p
      class="heart-text"
      :style="{
        'font-size': small ? '9px' : '15px',
        'margin-bottom': small ? '16px' : '24px',
      }"
    >
      {{ $t("popular_among_neighbors") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "StarWithText",
  props: {
    small: {
      type: Boolean,
      default: true,
    },
    textClass: {
      type: String,
      default: "pt-1 bold-2",
    },
    size: {
      type: Object,
      default: () => ({ width: 64, height: 30 }),
    },
    fillColor: {
      type: String,
      default: "#ffe400", // Amber color from Flutter example
    },

    textColor: {
      type: String,
      default: "#ce003c", // Default text color
    },
  },

  computed: {
    points() {
      const spikes = 16;
      const outerRadius = 50; // Half of the SVG viewBox size
      const innerRadius = outerRadius * 0.8;
      const centerX = 50; // Center of the SVG viewBox
      const centerY = 50;
      let points = [];

      for (let i = 0; i < spikes; i++) {
        const angle = (i * 2 * Math.PI) / spikes;
        const nextAngle = ((i + 1) * 2 * Math.PI) / spikes;

        const outerX = centerX + outerRadius * Math.cos(angle);
        const outerY = centerY + outerRadius * Math.sin(angle);

        const innerX =
          centerX + innerRadius * Math.cos((angle + nextAngle) / 2);
        const innerY =
          centerY + innerRadius * Math.sin((angle + nextAngle) / 2);

        points.push(`${outerX},${outerY}`);
        points.push(`${innerX},${innerY}`);
      }

      return points.join(" ");
    },
  },
};
</script>

<style scoped>
.heart-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heart-icon {
  /* Make the icon large enough */
  position: absolute;
}

.heart-text {
  font-weight: bold;
  text-align: center;
  color: #ce003c;
  position: absolute;
  font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
}
/* Add any styles you need here */
</style>
