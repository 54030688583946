<template>
  <v-container class="mt-0 pt-0">
    <v-row class="pa-2" align="start" justify="start">
      <v-col cols="12" class="pa-0"
        ><v-card
          color="error"
          elevation="0"
          class="ma-3"
          v-if="user.subscription.status == 'past_due'"
          ><v-col class="pt-0">
            <p class="bold-h2 pb-1">{{ $t("failed_deposit") }}</p>
            <p class="body-2">{{ $t("failed_deposit_date") }}</p>
            <v-row class="pa-3 pt-10">
              <v-btn
                color="surface"
                variant="outlined"
                elevation="0"
                class="text-none body-3"
                @click="canPauseMembership"
                >{{ $t("pause_membership") }}</v-btn
              >
              <v-btn
                color="success"
                elevation="0"
                class="text-none bold-1 pb-1 ml-4"
                :loading="isStartingOverdue"
                @click="startPayOverdue(false)"
                >{{ $t("transfer_now") }}</v-btn
              ></v-row
            >
          </v-col></v-card
        >
        <v-card
          color="secondary"
          elevation="0"
          class="ma-3"
          v-if="user.subscription.status == 'expiring'"
          ><v-col class="pt-0" style="color: #f5f5f9">
            <p class="bold-h2 pb-1">{{ $t("membership_ending") }}</p>
            <p class="body-2">
              {{
                $t("membership_ends_on") +
                getDayMonth(user.subscription.currentPeriodEnd) +
                $t("pause_or_resume_for_bonuses")
              }}
            </p>
            <v-row class="pa-3 pt-10">
              <v-btn
                color="surface"
                variant="outlined"
                elevation="0"
                class="text-none body-3"
                :loading="isStopCancelMembership"
                @click="stopCancelMembership"
                >{{ $t("resume") }}</v-btn
              >
              <v-btn
                color="primary"
                elevation="0"
                class="text-none bold-1 pb-1 ml-4"
                @click="canPauseMembership"
                >{{ $t("pause") }}</v-btn
              ></v-row
            >
          </v-col></v-card
        >
        <v-card
          color="surface"
          elevation="0"
          class="ma-3"
          v-if="
            user.subscription.status == 'active' &&
            user.subscription.resumeAt != null
          "
          ><v-col class="pt-0">
            <p class="bold-h2 pb-1">{{ $t("membership_paused") }}</p>
            <p class="body-2">
              {{
                $t("no_deposit") +
                getDayMonth(user.subscription.currentPeriodEnd) +
                $t("cancel_pause")
              }}
            </p>
            <v-row class="pa-3 pt-10">
              <v-btn
                color="primary"
                elevation="0"
                class="text-none bold-1 pb-1"
                @click="cancelPause"
                :loading="isCancelPause"
                :disabled="isCancelPause"
                >{{ $t("cancel_pause_action") }}</v-btn
              ></v-row
            >
          </v-col></v-card
        >
        <v-card
          color="surface"
          elevation="0"
          class="ma-3"
          v-if="
            user.subscription.status == 'paused' &&
            user.subscription.resumeAt != null
          "
          ><v-col class="pt-0">
            <p class="bold-h2 pb-1">{{ $t("membership_has_paused") }}</p>
            <p class="body-2">
              {{
                $t("membership_paused_date", {
                  date: get26DayMonthYear(user.subscription.resumeAt),
                })
              }}
            </p>
            <v-row class="pa-3 pt-10">
              <v-btn
                color="secondary"
                elevation="0"
                class="text-none body-3"
                @click="canPauseMembership"
                >{{ $t("change_pause") }}</v-btn
              >
              <v-btn
                color="primary"
                elevation="0"
                class="text-none bold-1 pb-1 ml-4"
                :loading="isStartingUpgrade"
                @click="startUpgrade(false)"
                >{{ $t("resume_now") }}</v-btn
              ></v-row
            >
          </v-col></v-card
        >
        <v-card
          color="secondary"
          elevation="0"
          class="ma-3"
          v-if="
            user.subscription.status == 'active' &&
            user.subscription.amount > user.subscription.previousAmount
          "
          ><v-col class="pt-0" style="color: #f5f5f9">
            <p class="bold-h2 pb-1">{{ $t("upgrade_membership_now") }}</p>
            <p class="body-2">
              {{
                $t("upgrade_membership_now_date", {
                  date: getDayMonth(user.subscription.currentPeriodEnd),
                })
              }}
            </p>
            <v-row class="pa-3 pt-10">
              <v-btn
                color="surface"
                variant="outlined"
                elevation="0"
                class="text-none bold-1 pb-1 ml-0"
                :loading="isStartingUpgrade"
                @click="startUpgrade(true)"
                >{{
                  $t("deposit_more", {
                    amount:
                      user.subscription.amount -
                      user.subscription.previousAmount,
                  })
                }}</v-btn
              ></v-row
            >
          </v-col></v-card
        >
      </v-col>
      <v-col align="center" cols="12" md="6"
        ><v-card elevation="0"
          ><v-col align="start"
            ><p class="bold-h3 text-center">{{ $t("your_membership") }}</p>
            <p class="body-2 pt-2">
              {{
                $t("your_balance") + ": " + user.credit + " " + $t("credits")
              }}
            </p>
            <p class="body-2 pt-2">
              {{
                (user.subscription.status == "paused"
                  ? $t("resumes_on")
                  : $t("next_deposit")) +
                " " +
                get26DayMonthYear(
                  user.subscription.resumeAt != null
                    ? user.subscription.resumeAt
                    : user.subscription.currentPeriodEnd
                )
              }}
            </p>

            <p class="body-2 pt-2">
              {{ $t("active_months") + " " + user.subscription.activeMonths }}
            </p>
            <p class="body-2 pt-2">
              {{
                $t("current_bonus") +
                " " +
                (user.subscription.currentBonus * 100).toFixed(0) +
                " %"
              }}
            </p>
            <v-expansion-panels variant="accordion" elevation="0" class="mt-5">
              <v-expansion-panel
                key="receipt"
                class="pa-0"
                style="background-color: white; border-radius: 10px"
              >
                <v-expansion-panel-title class="pa-0 pt-1 pr-10">
                  <v-list-item style="width: 100%; height: 100%">
                    <v-col class="pa-0">
                      <p class="bold-h4 pb-2">
                        {{ $t("previous_months") }}
                      </p>

                      <p class="body-3 pb-2">
                        {{ $t("view_past_transfers") }}
                      </p>
                    </v-col></v-list-item
                  >
                </v-expansion-panel-title>
                <v-expansion-panel-text class="pa-0 ma-0">
                  <v-card
                    class="ma-0 mt-2 pa-0"
                    elevation="0"
                    v-for="invoice in invoices"
                    :key="invoice._id"
                  >
                    <v-row class="pa-2 pl-6" align="center"
                      ><v-icon
                        :color="
                          invoice.status == 'paid' ||
                          invoice.status == 'succeeded'
                            ? 'success'
                            : 'warning'
                        "
                        >{{
                          invoice.status == "paid" ||
                          invoice.status == "succeeded"
                            ? "mdi-checkbox-marked-circle"
                            : "mdi-clock"
                        }}</v-icon
                      ><v-col cols="5" lg="5" class="pl-3"
                        ><p class="bold-1">{{ $t(invoice.type) }}</p>
                        <p class="body-2">
                          {{ getMonthYear(invoice.createdAt) }}
                        </p></v-col
                      >
                      <p class="body-2">{{ invoice.total + " kr" }}</p>
                      <v-row justify="end" class="pr-6">
                        <v-btn
                          variant="text"
                          class="text-none body-3"
                          @click="goToWebsite(invoice.receiptUrl)"
                          >{{ $t("receipt") }}</v-btn
                        ></v-row
                      >
                    </v-row></v-card
                  >
                </v-expansion-panel-text>
              </v-expansion-panel></v-expansion-panels
            >

            <p class="mt-4 bold-h4 text-center">
              {{
                $t("monthly_deposit", {
                  monthly_amount: user.subscription.amount,
                })
              }}
            </p>
            <p class="mt-1 bold-h4 text-center">
              {{
                "+" +
                (
                  user.subscription.amount * user.subscription.currentBonus
                ).toFixed(0) +
                $t("bonus_amount")
              }}
            </p>
            <v-row class="mt-4" justify="space-between">
              <v-col cols="12" lg="6">
                <v-btn
                  block
                  color="secondary"
                  elevation="0"
                  class="text-none button"
                  @click="canPauseMembership"
                  ><p style="color: #f5f5f9">
                    {{ $t("pause_membership") }}
                  </p></v-btn
                ></v-col
              >
              <v-col cols="12" lg="6">
                <v-btn
                  block
                  variant="text"
                  color="error"
                  class="text-none bold-1 pb-1"
                  @click="
                    user.subscription.status == 'paused'
                      ? (cancelPausedMembershipDialog = true)
                      : (cancelMembershipDialog = true)
                  "
                  >{{ $t("cancel_membership") }}</v-btn
                ></v-col
              >
            </v-row>
          </v-col></v-card
        >
      </v-col>
      <v-col align="center" cols="12" md="6">
        <v-card elevation="0"
          ><v-col align="start"
            ><p class="bold-h3 text-center">{{ $t("your_benefits") }}</p>
            <v-row class="pa-4" align="center">
              <v-icon color="success" size="x-large">mdi-check-circle</v-icon>
              <v-col class="pa-0 ml-4"
                ><p class="bold-h4">{{ $t("member_offers") }}</p>
                <p class="body-3">
                  {{ $t("exclusive_discounts") }}
                </p></v-col
              ></v-row
            >
            <p v-if="value < 1500" class="bold-h4 mt-4 text-center">
              {{
                "Sätt in " + (1500 - value).toFixed(0) + " kr mer för att få"
              }}
            </p>
            <v-row class="pa-4" align="center">
              <v-icon
                :color="value >= 1500 ? 'success' : 'error'"
                size="x-large"
                >{{
                  value >= 1500 ? "mdi-check-circle" : "mdi-close-circle"
                }}</v-icon
              >
              <v-col class="pa-0 ml-4"
                ><p class="bold-h4">{{ $t("deposit_bonus") }}</p>
                <p class="body-3">{{ $t("earn_more_money") }}</p></v-col
              ></v-row
            >
            <v-row class="pa-4" align="center">
              <v-icon
                :color="value >= 1500 ? 'success' : 'error'"
                size="x-large"
                >{{
                  value >= 1500 ? "mdi-check-circle" : "mdi-close-circle"
                }}</v-icon
              >
              <v-col class="pa-0 ml-4"
                ><p class="bold-h4">{{ $t("your_price") }}</p>
                <p class="body-3">
                  {{ $t("personalized_prices") }}
                </p></v-col
              ></v-row
            >
            <v-col class="pa-4" align="center">
              <v-row align="center" class="mt-2">
                <v-col cols="12">
                  <v-slider
                    v-model="value"
                    :max="5000"
                    :min="500"
                    :step="500"
                    inset
                    :disabled="nextStepLoading"
                    color="primary"
                    thumb-label
                    :class="
                      !$vuetify.display.xs && !$vuetify.display.sm
                        ? 'custom-slider'
                        : 'custom-sm-slider'
                    "
                    thumb-color="secondary"
                  ></v-slider>
                  <v-row
                    :class="
                      !$vuetify.display.xs && !$vuetify.display.sm
                        ? 'custom-row'
                        : 'custom-sm-row'
                    "
                  >
                    <p class="bold-h4 mr-4">500</p>
                    <p
                      :class="
                        !$vuetify.display.xs && !$vuetify.display.sm
                          ? 'ml-6 bold-h4'
                          : 'ml-5 bold-h4'
                      "
                    >
                      1500
                    </p>
                    <v-spacer></v-spacer>
                    <p class="bold-h4">5000+</p>
                  </v-row>
                </v-col>
                <v-row justify="center">
                  <v-col cols="10">
                    <v-text-field
                      v-model="value"
                      class="mt-5"
                      clearable
                      :rules="textFieldRules"
                      :disabled="isUpdatingMembership"
                      validate-on="submit"
                      color="primary"
                      bg-color="surface"
                      variant="outlined"
                      type="number"
                      label="Ange summa"
                      persistent-hint /></v-col
                ></v-row>
              </v-row>
              <v-row v-if="value != user.subscription.amount" justify="end">
                <v-col cols="12" lg="6"
                  ><v-btn
                    color="error text-none button"
                    elevation="0"
                    block
                    :disabled="isUpdatingMembership"
                    @click="value = user.subscription.amount"
                    >{{ $t("cancel") }}</v-btn
                  ></v-col
                >
                <v-col cols="12" lg="6">
                  <v-btn
                    color="primary"
                    elevation="0"
                    class="text-none button"
                    block
                    :loading="isUpdatingMembership"
                    :disabled="isUpdatingMembership"
                    @click="updateMembership"
                    >{{ $t("update_membership") }}</v-btn
                  ></v-col
                >
              </v-row>
            </v-col>
          </v-col></v-card
        >
      </v-col>
    </v-row>
  </v-container>
  <v-dialog
    max-width="500"
    v-model="cantPauseMembershipDialog"
    @click:outside="cantPauseMembershipDialog = false"
  >
    <v-card>
      <v-card-title>
        <p class="bold-h3">{{ $t("pause_membership") }}</p>
      </v-card-title>
      <v-card-text>
        <p class="body-2">{{ $t("cannot_pause_anymore") }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="button text-none"
          color="primary"
          variant="text"
          @click="cantPauseMembershipDialog = false"
        >
          {{ $t("ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    max-width="500"
    v-model="pauseMembershipDialog"
    @click:outside="pauseMembershipDialog = false"
  >
    <v-card>
      <v-card-title>
        <p class="bold-h3">{{ $t("pause_membership") }}</p>
      </v-card-title>
      <v-card-text>
        <p class="body-2">{{ $t("pause_limit") }}</p>
        <v-col class="pa-0 body-3"
          ><p
            v-if="
              user.subscription.pausedMonths != null &&
              user.subscription.pausedMonths > 0
            "
          >
            {{
              $t("paused_so_far") +
              " " +
              $t("months_count", { count: user.subscription.pausedMonths })
            }}
          </p>
          <p>{{}}</p></v-col
        >

        <v-select
          :items="pauseMonths"
          v-model="pauseDuration"
          color="primary"
          bg-color="surface"
          variant="outlined"
          item-title="title"
          item-value="value"
          validate-on="submit"
          single-line
          class="body-1 mt-2"
        >
        </v-select>
        <p class="body-3">
          {{ $t("next_deposit_on") + " " + getNextMonth(pauseDuration) }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          variant="text"
          @click="pauseMembershipDialog = false"
          color="primary"
          class="button text-none"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          class="button text-none"
          color="error"
          variant="text"
          :loading="isPauseMembership"
          @click="pauseMembership"
        >
          {{ $t("pause") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    max-width="500"
    v-model="cancelMembershipDialog"
    @click:outside="cancelMembershipDialog = false"
  >
    <v-card>
      <v-card-title>
        <p class="bold-h3">{{ $t("pause_instead") }}</p>
      </v-card-title>
      <v-card-text>
        <p class="body-2">{{ $t("pause_option") }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          variant="text"
          @click="
            {
              cancelMembershipDialog = false;
              areYouSureMembershipDialog = true;
            }
          "
          color="error"
          class="button text-none"
        >
          {{ $t("cancel_membership") }}
        </v-btn>
        <v-btn
          class="button text-none"
          color="primary"
          variant="text"
          @click="canPauseMembership"
        >
          {{ $t("pause_instead_button") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    max-width="500"
    v-model="areYouSureMembershipDialog"
    @click:outside="areYouSureMembershipDialog = false"
  >
    <v-card>
      <v-card-title>
        <p class="bold-h3">{{ $t("are_you_sure") }}</p>
      </v-card-title>
      <v-card-text>
        <p class="body-2">{{ $t("final_cancellation") }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          variant="text"
          @click="cancelMembership"
          color="error"
          :loading="isCancelMembership"
          :disabled="isCancelMembership"
          class="button text-none"
        >
          {{ $t("cancel_membership") }}
        </v-btn>
        <v-btn
          class="button text-none"
          color="primary"
          variant="text"
          :disabled="isCancelMembership"
          @click="areYouSureMembershipDialog = false"
        >
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    max-width="500"
    v-model="cancelPausedMembershipDialog"
    @click:outside="cancelPausedMembershipDialog = false"
  >
    <v-card>
      <v-card-title>
        <p class="bold-h3">{{ $t("lose_all_bonus") }}</p>
      </v-card-title>
      <v-card-text>
        <p class="body-2">{{ $t("confirm_cancellation") }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          variant="text"
          @click="cancelMembership"
          color="error"
          :loading="isCancelMembership"
          :disabled="isCancelMembership"
          class="button text-none"
        >
          {{ $t("abort") }}
        </v-btn>
        <v-btn
          class="button text-none"
          color="primary"
          variant="text"
          :disabled="isCancelMembership"
          @click="cancelPausedMembershipDialog = false"
        >
          {{ $t("continue_pause") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    max-width="500"
    v-model="upgradeMembershipDialog"
    @click:outside="upgradeMembershipDialog = false"
  >
    <v-card>
      <v-card-title>
        <p class="bold-h3">
          {{
            $t("deposit_required") +
            (upgrade
              ? user.subscription.amount - user.subscription.previousAmount
              : user.subscription.amount) +
            " kr"
          }}
        </p>
      </v-card-title>
      <v-card-text>
        <p class="body-2">
          {{
            upgrade
              ? $t("upgrade_message", {
                  amount:
                    user.subscription.amount - user.subscription.previousAmount,
                })
              : $t("deposit_message", { amount: user.subscription.amount })
          }}
        </p>
        <div id="payment-element" class="mt-5"></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          variant="text"
          @click="upgradeMembershipDialog = false"
          color="error"
          :disabled="isProcessing"
          class="button text-none"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          class="button text-none"
          color="primary"
          variant="text"
          :disabled="isProcessing"
          :loading="isProcessing"
          @click="handleSubmit"
        >
          {{ upgrade ? $t("upgrade") : $t("resume") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
    max-width="500"
    v-model="overdueMembershipDialog"
    @click:outside="overdueMembershipDialog = false"
  >
    <v-card>
      <v-card-title>
        <p class="bold-h3">
          {{ $t("deposit_required") + user.subscription.amount + " kr" }}
        </p>
      </v-card-title>
      <v-card-text>
        <p class="body-2">
          {{ $t("deposit_message", { amount: user.subscription.amount }) }}
        </p>
        <div id="payment-element" class="mt-5"></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          variant="text"
          @click="overdueMembershipDialog = false"
          color="error"
          :disabled="isProcessing"
          class="button text-none"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          class="button text-none"
          color="primary"
          variant="text"
          :disabled="isProcessing"
          :loading="isProcessing"
          @click="handleSubmit"
        >
          {{ $t("resume") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import bag2 from "../assets/images/bag.png";
import food_half from "../assets/images/food_half.png";
import star from "../assets/images/star.png";
import budget from "../assets/images/budget.png";
import piggySaving from "../assets/images/piggy-saving.png";
import UserAPI from "@/api/UserAPI"; // Import the UserAPI
import { mapState, mapActions, mapGetters } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import LoginModal from "@/components/LoginModal.vue";
import PaymentAPI from "@/api/PaymentAPI";
import axios from "axios";
import { useI18n } from "vue-i18n";
import {
  getDayMonth,
  get26DayMonthYear,
  getNextMonth,
  getMonthYear,
} from "../utils.js";

export default {
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  data() {
    return {
      pauseMonths: [],
      pauseDuration: 1,
      step: 1,
      value: 0,
      bag2: bag2,
      direct: false,
      pauseMembershipDialog: false,
      cancelMembershipDialog: false,
      areYouSureMembershipDialog: false,
      cancelPausedMembershipDialog: false,
      cantPauseMembershipDialog: false,
      upgradeMembershipDialog: false,
      overdueMembershipDialog: false,
      food_half: food_half,
      piggySaving: piggySaving,
      budget: budget,
      isUpdatingMembership: false,
      isPauseMembership: false,
      isCancelPause: false,
      isCancelMembership: false,
      isStopCancelMembership: false,
      isProcessing: false,
      isStartingUpgrade: false,
      isStartingOverdue: false,

      upgrade: false,
      star: star,
      nextStepLoading: false,
      isProcessing: false,
      loginModal: false,
      email: "",
      paymentIntent: undefined,
      stripe: {},
      items: ["Medlemskap", "Checkout"],
      invoices: [],
      elements: {},
      publishableKey: process.env.VITE_APP_STRIPE_KEY,
      message: "",
      form: false,
      emailRules: [
        (value) => {
          if (value) return true;

          return "Ange en e-postadress";
        },
        (value) => {
          if (/.+@.+\..+/.test(value)) return true;

          return "E-postadressen är inte giltig.";
        },
      ],
    };
  },
  computed: {
    ...mapState([
      "cart",
      "localCart",
      "location",
      "isCartLoading",
      "user",
      "locationModalIsVisible",
      "currentLang",
    ]),
  },
  methods: {
    getDayMonth,
    get26DayMonthYear,
    getNextMonth,
    getMonthYear,
    ...mapActions([
      "userUpdateMembership",
      "userPauseSubscription",
      "userUnpauseSubscription",
      "userCancelMembership",
      "userStopCancelMembership",
      "getUser",
    ]),
    goToWebsite(url) {
      if (url != null) {
        window.open(url, "_blank");
      }
    },

    async pauseMembership() {
      try {
        this.isPauseMembership = true;
        await this.userPauseSubscription({ nrMonths: this.pauseDuration });
        this.isPauseMembership = false;
        this.pauseMembershipDialog = false;
      } catch (error) {
        this.isPauseMembership = false;
      }
    },
    async cancelPause() {
      try {
        this.isCancelPause = true;
        await this.userUnpauseSubscription();
        this.isCancelPause = false;
      } catch (error) {
        console.log(error);
        this.isCancelPause = false;
      }
    },

    async updateMembership() {
      try {
        this.isUpdatingMembership = true;
        if (this.value < 500) {
          this.value = this.user.subscription.amount;
          this.$store.dispatch("vtoast/showToast", {
            message: this.t("value_above_500"),
            color: "error",
            timer: 5000,
          });
          this.isUpdatingMembership = false;
          return;
        }
        await this.userUpdateMembership({ amount: this.value });
        this.$store.dispatch("vtoast/showToast", {
          message: this.t("membership_updated"),
          color: "success",
          timer: 5000,
        });
        this.isUpdatingMembership = false;
      } catch (error) {
        this.isUpdatingMembership = false;
      }
    },
    async cancelMembership() {
      try {
        this.isCancelMembership = true;
        await this.userCancelMembership();
        this.areYouSureMembershipDialog = false;
        this.cancelPausedMembershipDialog = false;
        this.isCancelMembership = false;
        if (this.user.subscription.status == "canceled") {
          this.router.push({
            name: "handla",
          });
        }
      } catch (error) {
        this.isCancelMembership = false;
      }
    },
    async stopCancelMembership() {
      try {
        this.isStopCancelMembership = true;
        await this.userStopCancelMembership();

        this.isStopCancelMembership = false;
      } catch (error) {
        this.isStopCancelMembership = false;
      }
    },
    canPauseMembership() {
      this.cancelMembershipDialog = false;
      const pausedMonths = this.user.subscription?.pausedMonths ?? 0;
      if (pausedMonths != 3) {
        this.pauseMonths = Array.from(
          { length: 3 - pausedMonths },
          (_, index) => ({
            value: index + 1,
            title: this.t("months_count", {
              count: index + 1,
            }),
          })
        );
        this.pauseMembershipDialog = true;
      } else {
        this.cantPauseMembershipDialog = true;
      }
    },
    async startUpgrade(upgrade) {
      try {
        this.upgrade = upgrade;
        // Fetch the client secret from the server
        this.isStartingUpgrade = true;
        this.paymentIntent = await PaymentAPI.updgradeMemberShip(upgrade);

        const loader = "auto";
        this.elements = this.stripe.elements({
          customerOptions: {
            customer: this.user.stripeId,
            ephemeralKey: this.paymentIntent.ephemeralKey,
          },
          loader,
          allow_redisplay: "always",
          clientSecret: this.paymentIntent.client_secret,
          locale: this.currentLang,
        });
        this.isStartingUpgrade = false;
        this.upgradeMembershipDialog = true;
        await setTimeout(50);
        // Create and mount the payment element
        this.paymentElement = this.elements.create("payment");
        this.paymentElement.mount("#payment-element");
      } catch (error) {
        this.isStartingUpgrade = false;
        // Handle errors during Stripe initialization
        console.error("Error initializing Stripe:", error);
      }
    },
    async startPayOverdue() {
      try {
        this.isStartingOverdue = true;
        this.paymentIntent = await PaymentAPI.payOverdue();

        const loader = "auto";
        this.elements = this.stripe.elements({
          customerOptions: {
            customer: this.user.stripeId,
            ephemeralKey: this.paymentIntent.ephemeralKey,
          },
          loader,
          allow_redisplay: "always",
          clientSecret: this.paymentIntent.client_secret,
          locale: this.currentLang,
        });
        this.isStartingOverdue = false;
        this.overdueMembershipDialog = true;
        await setTimeout(50);
        // Create and mount the payment element
        this.paymentElement = this.elements.create("payment");
        this.paymentElement.mount("#payment-element");
      } catch (error) {
        this.isStartingOverdue = false;
        // Handle errors during Stripe initialization
        console.error("Error initializing Stripe:", error);
      }
    },
    async handleSubmit() {
      try {
        this.isProcessing = true;

        // Confirm the payment with Stripe
        const { error, paymentIntent } = await this.stripe.confirmPayment({
          elements: this.elements,
          confirmParams: {
            return_url:
              process.env.VITE_APP_FIREBASE_DEVELOPMENT == "production"
                ? "https://nearbystore.se/handla/member"
                : "https://dev.nearbystore.se/handla/member",
          },
          redirect: "if_required",
        });
        if (error) {
          console.error("Error confirming payment:", error);
          this.isProcessing = false;
        } else if (
          paymentIntent &&
          (paymentIntent.status === "succeeded" ||
            paymentIntent.status === "requires_capture")
        ) {
          this.isProcessing = false;
          this.overdueMembershipDialog = false;
          this.upgradeMembershipDialog = false;

          this.$store.dispatch("vtoast/showToast", {
            message: this.t("deposit_successful"),
            color: "success",
            timer: 5000,
          });
          setTimeout(() => {
            this.getUser();
          }, 2000);
        } else {
          this.isProcessing = false;
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          // Extract the reason from the error response
          const reason = error.response?.data?.reason || "An error occurred";
          if (reason == "promotion-error") {
            this.promoCode = "";
          }
          this.$store.dispatch("vtoast/showToast", {
            message: this.t(reason),
            color: "error",
            timer: 5000,
          });
        }
        this.isProcessing = false;
      }
    },
  },
  async activated() {
    this.invoices = await UserAPI.getInovices();
    this.value = this.user.subscription.amount;
  },
  async mounted() {
    this.stripe = await loadStripe(this.publishableKey, {
      betas: ["elements_customers_beta_1"],
      apiVersion: "2019-11-05",
    });
    this.invoices = await UserAPI.getInovices();
    this.value = this.user.subscription.amount;
  },
};
</script>
<style scoped>
.sticky-card {
  position: sticky;
  top: 175px;
}
.custom-slider {
  width: 300px;
}
.custom-sm-slider {
  width: 230px;
}
.custom-row {
  width: 340px;
}
.custom-sm-row {
  width: 270px;
}
:deep(.v-slider-track__background) {
  height: 15px !important;
}
:deep(.v-slider-track__fill) {
  height: 15px !important;
}
:deep(.v-slider-thumb__surface) {
  height: 30px !important;
  width: 30px !important;
}
</style>
