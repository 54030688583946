// PickupPoint.js
import axiosInstance from "./AxiosInstance";
import axios from "axios";
import store from "@/store"; // Import the Vuex store
/**
 * API for interacting with pickupPoint related endpoints.
 */
const PickupPointAPI = {
  /**
   * Retrieves all pickup points.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the pickupPoints.
   */
  getAllPickupPoints: ({ lng = 0, lat = 0 } = {}) => {
    const params = {
      lng,
      lat,
    };
    return axiosInstance.get("/pickupPoint/closest", { params });
  },

  suggestLocation: (email, location) => {
    const body = {
      email: email,
      location: location,
    };
    return axiosInstance.post("/pickupPoint/suggestLocation", body);
  },

  reverseGeocode: async (lat, lng) => {
    const google_key = import.meta.env.VITE_APP_GOOGLE_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${google_key}`;

    try {
      const response = await axios.get(url);
      const data = response.data;

      if (data.results && data.results.length > 0) {
        return data.results[0];
      } else {
        throw "No address found";
      }
    } catch (error) {
      throw "Error fetching address";
    }
  },

  getWarehouse: async () => {
    const storeId = store.state.storeId;
    const response = await axiosInstance.get(`/warehouse/` + storeId);

    return response.data;
  },
};

export default PickupPointAPI;
