<template>
  <div class="grid-container pr-2 pl-2">
    <!-- Content to display when not loading -->
    <div>
      <div v-if="isPhone || isTablet">
        <PhoneSearchField />
      </div>

      <!-- Render BannerContainer only if storeId is present -->
      <BannerContainer class="mt-5" />

      <div v-if="offerProducts.length > 0">
        <FeaturedProducts
          :featuredText="$t('offers')"
          :products="offerProducts"
          bannerId="banner._id"
          :offers="true"
        />
      </div>

      <!-- Loop through each text banner and display FeaturedProducts for each -->
      <div v-for="banner in textBanners" :key="banner._id">
        <FeaturedProducts
          :featuredText="banner.title"
          :products="banner.products"
          :bannerId="banner._id"
        />
      </div>

      <!-- AllProducts section -->
      <keep-alive>
        <v-col class="mt-10 pa-0">
          <p class="mb-10 bold-h1">{{ $t("all_products") }}</p>
          <AllProducts
            :products="allProducts"
            @loadMore="handleLoadMore"
            :loadingMoreProducts="loadingMoreProducts"
          />
        </v-col>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import BannerAPI from "@/api/BannerAPI";
import CategoryProductAPI from "@/api/CategoryProductAPI";
import CategoryNavigationMobile from "@/components/smallDevicesOnly/CategoryNavigationPhones.vue";
import { computed, onMounted, ref, watch } from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import AllProducts from "./AllProducts.vue";
import FeaturedProducts from "./FeaturedProducts.vue";
import BannerContainer from "./ProductBanner.vue";
import PhoneSearchField from "./SearchField.vue";
import { useHead } from "@unhead/vue";
export default {
  name: "ProductGrid",
  emits: ["loadMore"],
  activated() {
    useHead({
      title: "Stormarknaden nära dig | Beställ Idag och Få Leverans Samma Dag",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Handla och hämta - så enkelt är det! Utforska vårt breda sortiment och låga priser med leverans till ett hämtskåp nära dig.",
        },
      ],
      __key: "shopHead",
    });
  },
  components: {
    BannerContainer,
    FeaturedProducts,
    PhoneSearchField,
    AllProducts,
    CategoryNavigationMobile,
  },
  data() {
    return {
      allProducts: [],
      offerProducts: [],
      textBanners: [],
      page: 1,
      isPhone: false,
      isTablet: false,
      loadingMoreProducts: false,
    };
  },
  watch: {
    "cart.pickupPoint": {
      handler(newPickupPoint, oldPickupPoint) {
        console.log("pickupPoint changed");
        if (
          newPickupPoint != null &&
          oldPickupPoint != null &&
          newPickupPoint._id !== oldPickupPoint._id
        ) {
          this.fetchTextBanners();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState(["cart"]),
  },
  methods: {
    checkDevice() {
      const width = window.innerWidth;
      this.isPhone = width <= 600;
      this.isTablet = width > 600 && width <= 1024;
    },
    async fetchTextBanners() {
      try {
        const response = await BannerAPI.getAllBanners({
          imageBanner: false,
          textBanner: true,
        });
        this.textBanners = response.data;
      } catch (error) {
        console.error("Error fetching text banners:", error);
      }
    },

    async fetchProducts() {
      try {
        const response = await CategoryProductAPI.getProducts({
          page: this.page,
          storeId: this.storeId,
        });
        this.allProducts = [...this.allProducts, ...response.data];
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },

    async fetchOffers() {
      try {
        const response = await CategoryProductAPI.getProducts({
          storeId: this.storeId,
          offers: true,
        });
        this.offerProducts = [...this.offerProducts, ...response.data];
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    async handleLoadMore() {
      this.loadingMoreProducts = true;
      this.page += 1;
      await this.fetchProducts();
      this.loadingMoreProducts = false;
    },
  },
  async mounted() {
    this.loading = true;
    this.page = 1;
    this.checkDevice();
    this.fetchTextBanners();
    this.fetchProducts();
    this.fetchOffers();
    this.loading = false;
  },
  /*
  setup() {
    const store = useStore();
    const allProducts = ref([]);
    const offerProducts = ref([]);
    const textBanners = ref([]);
    const loading = ref(true); // Tracks if the data is still loading
    const page = ref(1);
    const isPhone = ref(false);
    const loadingMoreProducts = ref(false);
    const isTablet = ref(false);

    const storeId = computed(() =>store.state.storeId);

    const fetchTextBanners = async () => {
      if (!storeId.value) return;
      try {
        const response = await BannerAPI.getAllBanners({ textBanner: true });
        textBanners.value = response.data;
      } catch (error) {
        console.error("Error fetching text banners:", error);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await CategoryProductAPI.getProducts({
          page: page.value,
          storeId: storeId.value,
        });
        allProducts.value = [...allProducts.value, ...response.data];
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    const fetchOffers = async () => {
      try {
        const response = await CategoryProductAPI.getProducts({
          page: page.value,
          storeId: storeId.value,
          offers: true,
        });
        offerProducts.value = [...offerProducts.value, ...response.data];
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    watch(
      () => store.state.currentLang,
      async () => {


        try {
          fetchTextBanners();
          fetchOffers();
          const limit = allProducts.value.length;

          const response = await CategoryProductAPI.getProducts({
            limit: limit,
          });
          allProducts.value = response.data;
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      }
    );
    const updateLoadingState = () => {
      if (!storeId.value) {
        loading.value = allProducts.value.length === 0;
      } else {
        loading.value =
          textBanners.value.length === 0 || allProducts.value.length === 0;
      }
    };

    const handleLoadMore = async () => {
      loadingMoreProducts.value = true;
      page.value += 1;
      await fetchProducts();
      loadingMoreProducts.value = false;
    };

    const checkDevice = () => {
      const width = window.innerWidth;
      isPhone.value = width <= 600;
      isTablet.value = width > 600 && width <= 1024;
    };
    onMounted(() => {
      loading.value = true;
      page.value = 1;
      allProducts.value = [];
      offerProducts.value = [];
      fetchTextBanners();
      fetchProducts();
      fetchOffers();
    });

    // Watch for changes in banners and products to update the loading state
    watch([textBanners, allProducts], updateLoadingState);

    // Initialize device check
    checkDevice();
    window.addEventListener("resize", checkDevice);

    return {
      allProducts,
      handleLoadMore,
      storeId,
      loadingMoreProducts,
      textBanners,
      loading,
      isPhone,
      isTablet,
    };
  },*/
};
</script>

<style scoped>
.grid-container {
  width: 100%;
  margin-bottom: 125px;
  position: relative;
}

.phone-wrapper {
  width: 95%;
  margin: auto;
}
</style>
