<template>
  <v-card
    elevation="0"
    :color="color"
    class="ma-0 pa-0"
    v-if="cart.price.products < freeDeliveryThreshold"
    ><v-col align="start" class="pt-1"
      ><p class="bold-1">{{ $t("free_shipping") }}</p>
      <p class="body-3">
        {{ $t("free_shipping_amount", { amount: freeDeliveryThreshold }) }}
      </p>

      <v-row align="center" class="pa-4"
        ><p class="bold-1 pb-1">0 kr</p>
        <v-col>
          <v-progress-linear
            v-model="cart.price.products"
            color="primary"
            bg-opacity="0.5"
            height="25"
            class="rounded"
            :max="freeDeliveryThreshold"
            min="0"
          >
            <template v-slot:default="{ value }">
              <strong class="bold-1 pb-1" style="color: #f5f5f9"
                >{{ cart.price.products.toFixed(2) }} kr</strong
              >
            </template>
          </v-progress-linear>
        </v-col>
        <p class="bold-1 pb-1">{{ freeDeliveryThreshold }} kr</p></v-row
      >
    </v-col></v-card
  >
  <v-card elevation="0" color="secondary" class="ma-0 pa-0" v-else
    ><v-col align="start" class="pt-4 pa-5">
      <p class="bold-h4 text-center" style="color: #f5f5f9">
        {{ "🎉 " + $t("congratulations_free_shipping") + " 🎉" }}
      </p>
    </v-col></v-card
  >
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    color: {
      type: String,
      default: "surface", // Default to plus icon
    },
  },
  data() {
    return {
      skill: 20,
    };
  },
  computed: {
    ...mapState(["cart", "warehouse", "localCart"]),
    freeDeliveryThreshold() {
      return this.cart.pickupPoint != null
        ? this.cart.pickupPoint.freeDeliveryThreshold
        : this.cart.pickupAddress != null && this.warehouse != null
        ? this.warehouse.freeHomeDeliveryThreshold
        : 0;
    },
  },
};
</script>
