<template>
  <!-- Sidebar for category navigation. Displays a list of categories and subcategories. -->
  <v-navigation-drawer
    v-model="categoryModalIsVisible"
    :permanent="!$vuetify.display.xs"
    :temporary="$vuetify.display.xs"
    color="background"
    app
    width="250"
  >
    <v-list-item
      @click="
        $router.push({
          name: 'handla',
        })
      "
      class="pa-2"
    >
      <v-row class="pa-4 pl-4">
        <v-icon class="mr-4" color="primary">mdi-home</v-icon>

        <p class="body-2 pb-1">
          {{ $t("home") }}
        </p>
      </v-row>
    </v-list-item>
    <v-list-item
      @click="
        $router.push({
          name: 'offers',
        })
      "
      class="pa-2"
    >
      <v-row class="pa-4 pl-4">
        <v-icon class="mr-4" color="primary">mdi-alert-octagram</v-icon>

        <p class="body-2 pb-1">
          {{ $t("offers") }}
        </p>
      </v-row>
    </v-list-item>
    <v-list-item
      @click="
        $router.push({
          name: 'recipe',
        })
      "
      class="pa-2"
    >
      <v-list-item-content>
        <v-row class="pa-4 pl-4" cols="12">
          <v-icon class="mr-3" color="primary"
            >mdi-book-open-page-variant</v-icon
          >

          <p class="body-2 pb-1">
            {{ $t("recipes") }}
          </p>
        </v-row>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      @click="
        $router.push({
          name: 'pickupPoints',
        })
      "
      class="pa-2"
    >
      <v-list-item-content>
        <v-row class="pa-4 pl-4" cols="12">
          <v-icon class="mr-3" color="primary">mdi-map-marker</v-icon>

          <p class="body-2 pb-1">
            {{ $t("pickup_locations") }}
          </p>
        </v-row>
      </v-list-item-content>
    </v-list-item>
    <v-list-item @click="navigateToMember" class="pa-2">
      <v-list-item-content>
        <v-row class="pa-4 pl-4" cols="12">
          <v-icon class="mr-3" color="primary">mdi-ticket</v-icon>

          <p class="body-2 pb-1">
            {{ $t("membership") }}
          </p>
        </v-row>
      </v-list-item-content>
    </v-list-item>
    <p class="pa-2 bold-h4">Kategorier</p>
    <v-expansion-panels v-model="panel" variant="accordion" elevation="0">
      <v-expansion-panel
        v-for="(category, index) in parentCategories"
        :key="category._id"
        style="background-color: #ececec"
      >
        <v-expansion-panel-title
          @click="toggleCategory(category.category._id)"
          :style="{
            'border-radius': 0,

            color: selectedCategory === category.category._id ? '#003A70' : '',
          }"
        >
          <v-list-item style="width: 100%; height: 100%">
            <template v-slot:prepend>
              <v-img
                :src="category.category.logo.url"
                :alt="category.category.logo.alt"
                height="40"
                width="25"
                class="mr-4"
              />
            </template>

            <p class="body-2 pb-1">
              {{ category.category.name }}
            </p></v-list-item
          >
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pt-2 pb-2">
          <v-list-item
            v-for="childId in category.category.children"
            :key="childId"
            @click="navigateToSubcategory(category.category._id, childId)"
            class="pl-4 pt-2 pb-2"
            :style="{
              'border-radius': 0,

              color: selectedSubCategory === childId ? '#C94631' : '',
            }"
          >
            <p class="body-3">
              {{ getChildName(childId) }}
            </p>
          </v-list-item>
        </v-expansion-panel-text></v-expansion-panel
      >
    </v-expansion-panels>

    <!--
    <v-list dense nav flat>

      <div class="navigation-header" v-if="fullscreen">
        <h2 class="navigation-title">Kategorier</h2>
        <a class="navigation-link" @click="navigateBack">X</a>
      </div>

      <div v-for="(category, index) in parentCategories" :key="category._id">

        <v-list-item
          @click="toggleCategory(category.category._id)"
          class="customPrepend pa-1 pl-2"
          color="error"
          :style="{
            'border-radius': 0,
            backgroundColor:
              selectedCategory === category.category._id ? '#C94631' : '',
            color: selectedCategory === category.category._id ? '#F5F5F9' : '',
          }"
        >
          <template v-slot:prepend>
            <v-img
              :src="category.category.logo.url"
              height="40"
              width="25"
              class="mr-2"
            />
          </template>
          <template v-slot:append>
            <v-icon>{{
              selectedCategory === category.category._id
                ? "mdi-chevron-down"
                : "mdi-chevron-right"
            }}</v-icon>
          </template>
          <p class="body-2">
            {{ category.category.name }}
          </p>
        </v-list-item>

        <v-list-item
          v-for="childId in category.category.children"
          :key="childId"
          v-show="selectedCategory === category.category._id"
          @click="navigateToSubcategory(category.category._id, childId)"
          class="pl-4"
          :style="{
            'border-radius': 0,
            backgroundColor: selectedSubCategory === childId ? '#0c80c2' : '',
            color: selectedSubCategory === childId ? '#F5F5F9' : '',
          }"
        >
          <p class="body-3">{{ getChildName(childId) }}</p>
        </v-list-item>
      </div>
    </v-list>
  -->
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { useRouter } from "vue-router";
import router from "@/plugins/router";
import { mapFields } from "vuex-map-fields";
import Breadcrumbs from "./Breadcrumbs.vue";
export default {
  name: "CategoryNavigation",
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Breadcrumbs,
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  async mounted() {
    if (this.$route.name != "ProductDetail") {
      this.selectedCategory = this.$route.params.categoryName;
      this.panel = this.parentCategories.indexOf((cat) => {
        return true;
      });
      this.parentCategories.forEach((element, index) => {
        if (element.category._id == this.$route.params.categoryName) {
          this.panel = index;
        }
      });

      this.selectedSubCategory =
        this.$route.params.categoryName +
        "." +
        this.$route.params.subcategoryName;
    }
    this.checkDevice();
  },

  watch: {
    $route: function (newRoute, oldRoute) {
      if (newRoute.name != "ProductDetail") {
        this.selectedCategory = newRoute.params.categoryName;
        this.selectedSubCategory =
          newRoute.params.categoryName + "." + newRoute.params.subcategoryName;
        if (newRoute.params.subcategoryName != undefined) {
          this.parentCategories.forEach((element, index) => {
            if (element.category._id == this.$route.params.categoryName) {
              this.panel = index;
            }
          });
        }
      }
      if (newRoute.name != "category" && newRoute.name != "ProductDetail") {
        this.panel = "";
      }
    },
  },
  computed: {
    ...mapFields(["categoryModalIsVisible", "user"]),
    ...mapGetters(["categories"]),
    drawer() {
      if (this.$route.meta.mainDrawer != undefined) {
        return this.$route.meta.mainDrawer;
      } else if (this.isPhone) {
        return false;
      } else {
        return true;
      }
    },
    parentCategories() {
      return this.categories.filter((cat) => !cat.category.parent);
    },
  },
  data() {
    return {
      selectedCategory: null,
      selectedSubCategory: null,
      isPhone: false,
      isTablet: false,
      panel: "",
    };
  },

  methods: {
    navigateToMember() {
      console.log(this.user);
      if (
        this.user !== null &&
        this.user.subscription !== null &&
        this.user.subscription.status !== "canceled" &&
        this.user.subscription.status !== "incomplete"
      ) {
        this.router.push({
          name: "member",
        });
      } else {
        this.router.push({
          name: "membership",
        });
      }
    },
    checkDevice() {
      const width = window.innerWidth;
      this.isPhone = width <= 600;
      this.isTablet = width > 600 && width <= 1024;
    },
    toggleCategory(categoryId) {
      this.router.push({
        name: "category",
        params: {
          categoryName: categoryId,
        },
      });
    },
    getChildName(childId) {
      const childCategory = this.categories.find(
        (cat) => cat.category._id === childId
      );
      return childCategory ? childCategory.category.name : "";
    },
    navigateToSubcategory(categoryId, fullSubcategoryId) {
      const subcategoryId = fullSubcategoryId.split(".").pop();
      this.router.push({
        name: "category",
        params: {
          categoryName: categoryId,
          subcategoryName: subcategoryId,
        },
      });
    },
    navigateBack() {
      this.router.back();
    },
  },
};
</script>

<style scoped>
::v-deep .v-list-item__content {
  display: flex;
  max-width: 100%;
}
:deep(.v-expansion-panel-text__wrapper) {
  padding: 0;
}
:deep(.v-expansion-panel-title) {
  padding: 8px;
  padding-left: 12px;
  padding-right: 30px;
}
:deep(.v-expansion-panel__shadow) {
  box-shadow: none !important;
}
/* Hide sidebar on screens narrower than 1024px (iPad landscape width) when not fullscreen */
@media (max-width: 1023px) {
  .sidebar:not(.fullscreen-sidebar) {
    display: none;
  }
}

/* Fullscreen sidebar styles */
.fullscreen-sidebar {
  width: 100%;
  top: 0;
  height: 100vh;
  position: absolute;
  z-index: 1000;
}

/* Navigation header for fullscreen mode */
.navigation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #e01010;
}

.navigation-title {
  font-weight: bold;
  font-size: 22px;
  color: white;
}

.navigation-link {
  font-size: 24px;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

.categories-list {
  margin-top: 20px;
}

.category-card,
.subcategory-card {
  font-weight: normal;
  font-size: 18px;
  color: white;
  height: 60px;
  border-radius: 0;
  margin: 0 !important;
  background-color: #ececec;
  border-bottom: 1px solid white;
  position: relative;
}

.category-card:last-child {
  border-bottom: none;
}

.subcategory-card {
  height: auto;
  padding: 20px !important;
  background-color: #0c80c2; /* Light blue for subcategories */
}

.category-icon {
  position: absolute;
  font-size: 22px;
  right: 30px;
}

.white--text,
.black--text {
  margin-left: 20px;
}
.customPrepend :deep(.v-list-item__append .v-list-item__spacer) {
  width: 0px;
}
.v-list,
.v-list-item-group,
.v-list-item,
.v-list-item--dense {
  padding: 0;
  margin: 0;
}
</style>
